import React, { useContext, useEffect, useRef, useState } from 'react';

import { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import { Collapse } from 'react-collapse';
import { Editor as EditorType, JSONContent } from '@tiptap/core';
import { EditorContent, useEditor } from '@tiptap/react';
import { generateJSON } from '@tiptap/html';
import { Theme as theme } from '~/theme';
import { useClickOutside } from '~/hooks';
import EditorAddons from './EditorAddons';
import EditorContainer from './EditorContainer';
import EditorToolbar from './EditorToolbar/EditorToolbar';
import uploadFile from './helpers/uploadFile';

import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import FileHandler from '@tiptap-pro/extension-file-handler';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Superscript from '@tiptap/extension-superscript';
import Placeholder from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import TiptapText from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';

import CheckedList from './extensions/CheckedList';
import File from './extensions/File';
import ImageZoom from './extensions/ImageZoom';
import readFile from '~/helpers/readFile';
import { EditorContext } from './EditorContextProvider';

import { useMenuState } from '@szhsin/react-menu';
import LinkToolbar from './LinkToolbar';

const commonExtensions = [
  Bold,
  BulletList,
  CheckedList,
  Document,
  File,
  HardBreak,
  Heading.configure({ levels: [1] }),
  Highlight.configure({ multicolor: true }),
  History,
  ImageZoom,
  Italic,
  ListItem,
  OrderedList,
  Paragraph,
  Superscript,
  Strike,
  TiptapText,
  Underline,
];

// IMPORTANT: update extensions list on BE side too. see apps/wl-graphql/src/integration/tiptap.ts
export const extensions = [...commonExtensions, Link.configure({ autolink: false })];

export const parseValue = (value?: string | null): JSONContent | string => {
  if (!value) {
    return '';
  }
  try {
    const json = JSON.parse(value);
    return json;
  } catch {
    const html =
      value.toLowerCase().indexOf('<p') >= 0
        ? value
        : value
            .replaceAll(/\n+/g, '\n')
            .split('\n')
            .map((chunk) => `<p>${chunk}</p>`)
            .join('');
    return generateJSON(html, extensions);
  }
};

type EditorInnerProps = Omit<BaseHOCPropsWithoutRef<'div'>, 'onChange'> & {
  value?: string | null;
  valueHash?: string | number;
  onChange?: (value: string) => void;
  onLengthChange?: (length: number) => void;
  allowFileAttachments?: boolean;
  allowImageAttachments?: boolean;
  autoFocus?: boolean;
  inputMaxHeight?: number;
  isCollapsible?: boolean;
  isDisabled?: boolean;
  isFlush?: boolean;
  isPlainText?: boolean;
  onCancel?: () => void;
  onCreate?: () => void;
  onUpdate?: () => void;
  onUploadStart?: () => void;
  onUploadEnd?: () => void;
  onUploadError?: (error: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  search?: string | null;
  toolbarAddons?: React.ReactNode;
};

export type EditorProps = Omit<BaseHOCPropsWithoutRef<'div'>, 'onChange'> & EditorInnerProps;

const Editor = React.forwardRef<HTMLDivElement, EditorProps>(function Editor(
  {
    value,
    valueHash,
    onChange,
    onLengthChange,
    search,
    placeholder = '',
    inputMaxHeight,
    toolbarAddons,
    readOnly = false,
    autoFocus,
    isCollapsible,
    isFlush,
    isPlainText,
    isDisabled = false,
    allowImageAttachments = true,
    allowFileAttachments = false,
    onUploadStart,
    onUploadEnd,
    onUploadError,
    onCreate,
    onUpdate,
    onCancel,
    ...props
  },
  ref,
) {
  const editorContext = useContext(EditorContext);
  onUploadStart = onUploadStart ?? editorContext.onUploadStart;
  onUploadEnd = onUploadEnd ?? editorContext.onUploadEnd;
  onUploadError = onUploadError ?? editorContext.onUploadError;

  // state of the addons collapse
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  useClickOutside(containerRef, isCollapseOpen, setIsCollapseOpen);

  // loading on save/update buttons click
  const [loading, setLoading] = useState(0);
  const isLoading = loading > 0;

  // required for tracking changes emitted from outside
  const valueRef = useRef(value);

  // constants
  const MAX_IMAGE_SIZE = 10 * 1024 * 1024; // 10MB
  const MAX_FILE_SIZE = 250 * 1024 * 1024; // 250MB
  const MAX_IMAGE_SIZE_ERROR_MESSAGE = `Image size should be less than ${Math.round(MAX_IMAGE_SIZE / (1024 * 1024))}MB`;
  const MAX_FILE_SIZE_ERROR_MESSAGE = `File size should be less than ${Math.round(MAX_FILE_SIZE / (1024 * 1024))}MB`;
  const MAX_SIZE_ERROR_MESSAGE = MAX_IMAGE_SIZE_ERROR_MESSAGE + '. ' + MAX_FILE_SIZE_ERROR_MESSAGE;
  const IMAGE_FORMAT_ERROR_MESSAGE = 'Upload failed. Only images are allowed.';

  // link toolbar
  const [linkToolbarState, toggleLinkToolbar] = useMenuState({ transition: true });
  const linkTarget = useRef<HTMLElement | null>(null);

  const LinkToolbarShowTimer = useRef<ReturnType<typeof setTimeout>>();
  const LinkToolbarHideTimer = useRef<ReturnType<typeof setTimeout>>();

  const LINK_TOOLBAR_SHOW_DELAY = 500;
  const LINK_TOOLBAR_HIDE_DELAY = 500;

  // create tiptap editor
  const editor = useEditor({
    autofocus: autoFocus && !readOnly ? 'end' : false,
    content: parseValue(value),
    editable: !readOnly,
    extensions: [
      ...extensions,
      Dropcursor.configure({ width: 2, color: theme.colors.primary }),
      FileHandler.configure({ onDrop: handleUpload }),
      Placeholder.configure({ placeholder }),
    ],
    immediatelyRender: false,
    onUpdate: ({ editor }) => {
      const newValue = editor.isEmpty ? '' : JSON.stringify(editor.getJSON());
      if (onLengthChange) {
        onLengthChange(editor.state.doc.textContent.length);
      }
      if (valueRef.current !== newValue && (valueRef.current || newValue)) {
        valueRef.current = newValue;
        onChange?.(newValue);
      }
    },
  });

  // update editor editable option
  useEffect(() => {
    if (editor) {
      editor.setEditable(!readOnly);
    }
  }, [editor, readOnly]);

  // update content if changes came from outside
  useEffect(() => {
    if (editor) {
      valueRef.current = value;
      editor.commands.setContent(parseValue(value));
    }
  }, [editor, valueHash, readOnly]);

  // always update value in readonly mode
  useEffect(() => {
    if (editor && readOnly && valueRef.current !== value) {
      valueRef.current = value;
      editor.commands.setContent(parseValue(value));
    }
  }, [editor, value, readOnly]);

  // highlight search words
  useEffect(() => {
    if (editor && value && search && readOnly) {
      const html = editor.getHTML().replaceAll(search, `<mark>${search}</mark>`);
      editor.commands.setContent(html);
    }
  }, [editor, value, search, readOnly]);

  // open collapse on editor focus
  useEffect(() => {
    if (editor) {
      editor.on('focus', () => setIsCollapseOpen(true));
    }
  }, [editor]);

  // show tooltip over hovered links
  useEffect(() => {
    if (editor && !readOnly) {
      const handleMouseOver = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (target.tagName !== 'A') {
          return;
        }

        // start show toolbar timer (toolbar hidden)
        if (!linkTarget.current || linkTarget.current !== target) {
          LinkToolbarShowTimer.current = setTimeout(() => showLinkToolbar(editor, target), LINK_TOOLBAR_SHOW_DELAY);
        }

        // clear hide toolbar timer (toolbar visible)
        if (linkTarget.current && linkTarget.current === target) {
          clearTimeout(LinkToolbarHideTimer.current);
        }
      };

      const handleMouseOut = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (target.tagName !== 'A') {
          return;
        }

        // clear show toolbar timer (toolbar hidden)
        if (!linkTarget.current || linkTarget.current !== target) {
          clearTimeout(LinkToolbarShowTimer.current);
        }

        // start hide toolbar timer (toolbar visible)
        if (linkTarget.current && linkTarget.current === target) {
          LinkToolbarHideTimer.current = setTimeout(hideLinkToolbar, LINK_TOOLBAR_HIDE_DELAY);
        }
      };

      // get the DOM element of the editor
      const editorElement = editor.view.dom;

      // add event listeners to the editor's DOM element
      editorElement.addEventListener('mouseover', handleMouseOver);
      editorElement.addEventListener('mouseout', handleMouseOut);

      // clean up the event listeners when the component is unmounted or editor changes
      return () => {
        editorElement.removeEventListener('mouseover', handleMouseOver);
        editorElement.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [editor, readOnly]);

  // toggle link toolbar
  const showLinkToolbar = (editor: EditorType, target: HTMLElement) => {
    const { from, to } = editor.state.selection;

    if (from === to) {
      linkTarget.current = target;
      toggleLinkToolbar(true);
    }
  };

  const hideLinkToolbar = () => {
    linkTarget.current = null;
    toggleLinkToolbar(false);
  };

  // handle upload
  async function handleUpload(editor: EditorType, files: File[], pos: number) {
    if (!allowImageAttachments && !allowFileAttachments) {
      return;
    }

    let hasImageFormatError = false;
    let hasImageSizeError = false;
    let hasFileSizeError = false;

    for (const file of files) {
      if (!file.type.includes('image') && !allowFileAttachments) {
        hasImageFormatError = true;
        break;
      }

      if (file.type.includes('image') && file.size > MAX_IMAGE_SIZE) {
        hasImageSizeError = true;
        break;
      }

      if (!file.type.includes('image') && file.size > MAX_FILE_SIZE) {
        hasFileSizeError = true;
        break;
      }
    }

    if (hasImageFormatError) {
      onUploadError?.(IMAGE_FORMAT_ERROR_MESSAGE);
      return false;
    }

    if (hasImageSizeError || hasFileSizeError) {
      onUploadError?.(
        hasImageSizeError && hasFileSizeError ? MAX_SIZE_ERROR_MESSAGE : hasImageSizeError ? MAX_IMAGE_SIZE_ERROR_MESSAGE : MAX_FILE_SIZE_ERROR_MESSAGE,
      );
      return false;
    }

    onUploadStart?.();

    type ImageSize = { width: number; height: number };

    async function imageZoomResolver(file: File) {
      try {
        const url = await uploadFile(file, onUploadError);
        const image = new Image();
        image.src = url as string;
        const { width, height }: ImageSize = await new Promise<ImageSize>(
          (resolve) => (image.onload = () => resolve({ width: image.width, height: image.height })),
        );
        return `${url}?width=${width}&height=${height}`;
      } catch (error) {
        console.error(error);
        throw new Error('Failed to upload file');
      }
    }

    for (const file of files) {
      setLoading((loading) => loading + 1);
      const node = file.type.includes('image')
        ? {
            type: 'imageZoom',
            attrs: {
              src: (await readFile(file)).src,
              alt: file.name,
              resolver: imageZoomResolver(file),
            },
          }
        : {
            type: 'file',
            attrs: {
              src: '',
              name: file.name,
              isUploading: true,
              resolver: uploadFile(file, onUploadError),
            },
          };
      node.attrs.resolver.finally(() => {
        setLoading((loading) => loading - 1);
      });
      editor.chain().insertContentAt(pos, node).run();
    }

    onUploadEnd?.();
  }

  // cancel, add, save
  function handleCancel() {
    onCancel!();
  }

  async function handleUpdate() {
    setLoading((loading) => loading + 1);
    await onUpdate!();
    setLoading((loading) => loading - 1);
  }

  async function handleCreate() {
    setLoading((loading) => loading + 1);
    await onCreate!();
    setLoading((loading) => loading - 1);
  }

  // addons
  const editorAddons = (
    <EditorAddons
      allowFileAttachments={allowFileAttachments}
      editor={editor!}
      handleCancel={handleCancel}
      handleCreate={handleCreate}
      handleUpdate={handleUpdate}
      handleUpload={handleUpload}
      isLoading={isLoading}
      onCancel={onCancel}
      onCreate={onCreate}
      onUpdate={onUpdate}
      toolbarAddons={toolbarAddons}
    />
  );

  // helpers
  const showAddons = (allowImageAttachments || allowFileAttachments) && !readOnly;
  const isCollapseOpened = Boolean(editor && (isCollapseOpen || editor.getHTML() !== '<p></p>'));

  // plain text
  if (isPlainText) {
    return editor?.getText();
  }

  return (
    <>
      <EditorContainer
        ref={(node: HTMLDivElement) => {
          containerRef.current = node;
          if (ref) {
            (ref as any).current = node;
          }
        }}
        inputMaxHeight={inputMaxHeight}
        isCollapsible={isCollapsible}
        isDisabled={isDisabled}
        isFlush={isFlush}
        isReadOnly={readOnly}
        {...props}
      >
        {/* Input */}
        <EditorContent editor={editor} />

        {/* Addons */}
        {editor && (
          <>
            {/* Toolbar */}
            <EditorToolbar editor={editor} />

            {/* Collapse */}
            {showAddons && (isCollapsible ? <Collapse isOpened={isCollapseOpened}>{editorAddons}</Collapse> : editorAddons)}

            {/* Link toolbar */}
            <LinkToolbar
              editor={editor}
              target={linkTarget}
              onClose={hideLinkToolbar}
              onMouseEnter={() => {
                // clear hide toolbar timer (toolbar shown)
                if (linkTarget.current) {
                  clearTimeout(LinkToolbarHideTimer.current);
                }
              }}
              onMouseLeave={() => {
                // start hide toolbar timer (toolbar shown)
                if (linkTarget.current) {
                  LinkToolbarHideTimer.current = setTimeout(hideLinkToolbar, LINK_TOOLBAR_HIDE_DELAY);
                }
              }}
              {...linkToolbarState}
            />
          </>
        )}
      </EditorContainer>
    </>
  );
});

export default Editor;
