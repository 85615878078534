import { LenderType } from '~/generated/graphql';

export type LenderTypeOption = {
  value: LenderType;
  label: string;
};

export const LenderTypeLabels: Record<LenderType, string> = {
  [LenderType.Bank]: 'Bank',
  [LenderType.CreditUnion]: 'Credit Union',
  [LenderType.Private]: 'Private',
  [LenderType.LifeCompany]: 'Life Company',
  [LenderType.Agency]: 'Agency',
};

const lenderTypeOptions: LenderTypeOption[] = Object.entries(LenderTypeLabels).map(([value, label]) => ({
  value: value as LenderType,
  label,
}));

export default lenderTypeOptions;
