import { DropdownControlledBase } from '../Dropdown';
import { edit, trash2 } from '../Icon/icons';
import { Editor } from '@tiptap/core';
import { MenuState } from '@szhsin/react-menu';
import { RefObject, useEffect, useState } from 'react';
import { StyledDropdownMenu } from './EditorToolbar/EditorToolbar';
import { Text } from '~/components/type';
import EditorToolbarButton from './EditorToolbar/EditorToolbarButton';
import EditorToolbarLinkInput from './EditorToolbar/EditorToolbarLinkInput';
import Icon from '../Icon';

type LinkToolbarProps = {
  editor: Editor;
  target: RefObject<HTMLElement>;
  state?: MenuState;
  onClose: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

export default function LinkToolbar({ editor, target, state, onClose, onMouseEnter, onMouseLeave, ...props }: LinkToolbarProps) {
  const [isToolbarLinkVisible, setIsToolbarLinkVisible] = useState(false);

  const [linkUrl, setLinkUrl] = useState('');
  const [isLinkUrlInvalid, setIsLinkUrlInvalid] = useState(false);
  const [isLinkUrlInputFocused, setIsLinkUrlInputFocused] = useState(false);

  function handleLink(href: string) {
    if (!/^http/.test(href)) {
      href = `http://${href}`;
    }

    if (target.current) {
      const pos = editor.view.posAtDOM(target.current, 0);
      const node = editor.state.doc.nodeAt(pos);

      if (node) {
        // Check if the node has a link mark
        const linkMark = node.marks.find((mark) => mark.type.name === 'link');

        if (linkMark) {
          // Find the positions of the node at pos
          const nodeStart = pos;
          const nodeEnd = nodeStart + node.nodeSize;

          // Create a new link mark with the updated URL
          const linkMarkType = editor.schema.marks.link;
          const newLinkMark = linkMarkType.create({ href });

          // Remove the old link mark and add the new one
          const transaction = editor.state.tr.removeMark(nodeStart, nodeEnd, linkMark.type).addMark(nodeStart, nodeEnd, newLinkMark);

          // Apply the transaction to the editor state
          editor.view.dispatch(transaction);

          // Close popover
          onClose();
        }
      }
    }
  }

  function handleRemove(e: React.MouseEvent) {
    e.preventDefault();

    if (target.current) {
      const pos = editor.view.posAtDOM(target.current, 0);
      const node = editor.state.doc.nodeAt(pos);

      if (node) {
        // Check if the node has a link mark
        const linkMark = node.marks.find((mark) => mark.type.name === 'link');

        if (linkMark) {
          // Find the positions of the node at pos
          const nodeStart = pos;
          const nodeEnd = nodeStart + node.nodeSize;

          // Remove the link mark from the node's range
          editor.view.dispatch(editor.state.tr.removeMark(nodeStart, nodeEnd, linkMark.type));

          // Close popover
          onClose();
        }
      }
    }
  }

  function handleEdit(e: React.MouseEvent) {
    e.preventDefault();
    setIsToolbarLinkVisible(true);
  }

  // Cleanup form on open
  useEffect(() => {
    if (state === 'opening') {
      setLinkUrl(target.current?.getAttribute('href') || '');
    } else if (state === 'closed') {
      setIsLinkUrlInvalid(false);
      setIsToolbarLinkVisible(false);
    }
  }, [state, target]);

  return (
    <DropdownControlledBase align="center" arrow={true} direction="top" anchorRef={target} gap={-2} onClose={onClose} state={state} {...props}>
      <StyledDropdownMenu
        style={{ visibility: 'visible' }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => {
          if (!isLinkUrlInputFocused) {
            onMouseLeave();
          }
        }}
      >
        {isToolbarLinkVisible ? (
          <EditorToolbarLinkInput
            linkUrl={linkUrl}
            setLinkUrl={setLinkUrl}
            isLinkUrlInvalid={isLinkUrlInvalid}
            setIsLinkUrlInvalid={setIsLinkUrlInvalid}
            setIsLinkUrlInputFocused={setIsLinkUrlInputFocused}
            onLink={handleLink}
          />
        ) : (
          <>
            {/* Url */}
            <Text utils={{ px: 5, textTruncate: true }} style={{ width: 'auto', maxWidth: 250 }}>
              {linkUrl}
            </Text>

            {/* Divider */}
            <Text utils={{ color: 'gray300' }}>|</Text>

            {/* Icons */}
            <EditorToolbarButton>
              <Icon icon={edit} onClick={handleEdit} role="button" />
            </EditorToolbarButton>

            <EditorToolbarButton>
              <Icon icon={trash2} onClick={handleRemove} role="button" />
            </EditorToolbarButton>
          </>
        )}
      </StyledDropdownMenu>
    </DropdownControlledBase>
  );
}
