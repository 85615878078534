import React, { Fragment } from 'react';
import { Badge, Divider, Status } from '~/components/ui';
import { CapitalType, DealType, LenderProgramFragment } from '~/generated/graphql';
import { capitalTypeOptions, DealTypeLabels, dealTypeMatching } from '~/data';
import { capitalTypeOptionsMap } from '~/data/capitalTypeOptions';
import { Block, Col, FlexRow, Row } from '~/components/layout';
import { getBaseApiUrl } from '~/helpers/getBaseUrl';
import { getProgramExitFeeValue } from '~/helpers/lender/getProgramExitFeeValue';
import { getProgramLoanValue } from '~/helpers/lender/getProgramLoanValue';
import { getProgramOriginationFeeValue } from '~/helpers/lender/getProgramOriginationFeeValue';
import { getProgramOtherFeesValue } from '~/helpers/lender/getProgramOtherFeesValue';
import { getProgramRateValue } from '~/helpers/lender/getProgramRateValue';
import { getProgramTermValue } from '~/helpers/lender/getProgramTermValue';
import { Heading, Text, CappedList, TextCollapsible } from '~/components/type';
import { List, ListItem } from '~/components/list';
import { MatchModalDeal } from './MatchModal';
import { NotFound } from '~/components/not-found';
import { programAmortizationLabels } from '~/data/programAmortizationOptions';
import { statesMap } from '~/helpers/statesMap';
import dynamic from 'next/dynamic';
import formatMoney from '~/helpers/formatMoney';
import ProgramRecourses from '~/containers/Lender/ProgramRecourses';
import UpdatedBy from '~/containers/UpdatedBy';

const Tooltip = dynamic(() => import('~/components/vendor/Tooltip'), {
  ssr: false,
});

export function getBadgeVariant(score: number) {
  return score >= 80 ? 'success' : score >= 60 ? 'warning' : 'danger';
}

export function getBadgeIcon(score: number) {
  return score >= 80 ? 'check' : score >= 60 ? 'minus' : 'x';
}

type MatchModalMatchProps = {
  enableBlur?: boolean;
  program?: LenderProgramFragment;
  deal: MatchModalDeal;
};

export default function MatchModalMatch({ program, deal, enableBlur }: MatchModalMatchProps) {
  const badgeBg = getBadgeVariant(program?.matchScore?.score!);
  const lendingStates = program?.lending_states?.map((state) => statesMap?.[state] ?? state) ?? [];

  const dealCapitalType = capitalTypeOptions.find(({ value }) => value === program?.matchScore?.capitalType)?.label;
  const dealState = statesMap[deal?.project?.coordinates?.cityState?.split(', ')[1]!];
  const dealLoanValue = (deal?.financials?.[program?.matchScore?.capitalType as CapitalType] || deal?.financials?.loanRequestAmount)!;
  const dealLTV = Math.round((dealLoanValue / deal?.financials?.completedValue!) * 1000) / 10;
  const dealLTC = Math.round((dealLoanValue * 1000) / deal?.financials?.totalCost!) / 10;

  return (
    <>
      {program?.matchScore?.score! > 0 && (
        <>
          <FlexRow utils={{ alignItems: 'center', mb: 4 }}>
            <Heading as="h3" utils={{ fontSize: 'xl', mr: 'auto' }}>
              {program?.name}
            </Heading>
            <Badge utils={{ bgColor: badgeBg, color: 'white' }}>{Math.round((program?.matchScore?.score ?? 1) * 10) / 10}%&nbsp; Match</Badge>
          </FlexRow>
          {program?.notes?.length! > 0 && !enableBlur && (
            <>
              <Text utils={{ fontSize: 'sm' }}>
                <TextCollapsible maxWords={30}>{program?.notes as string}</TextCollapsible>
              </Text>
            </>
          )}
          <List gutter={3} utils={{ fontSize: 'sm', mt: 6, mb: 10 }}>
            <ListItem>
              <Row gutter={3} utils={{ alignItems: 'center' }}>
                <Col span="auto">Capital Type</Col>
                <Col>
                  <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                </Col>
                <Col span="auto">{program?.capital_types?.map((capitalType) => capitalTypeOptionsMap[capitalType].label).join(', ')}</Col>

                {(() => {
                  const isMatch = program && Number(program?.matchScore?.financingType) !== 0;
                  if (!program?.matchScore?.financingType) {
                    return null;
                  }

                  return (
                    <Col span="auto">
                      <Status
                        icon={getBadgeIcon(Number(program?.matchScore?.financingType))}
                        variant={getBadgeVariant(Number(program?.matchScore?.financingType))}
                        data-tooltip-id="financingType"
                      />
                      <Tooltip id="financingType">
                        <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                          <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                            Capital Type
                          </Heading>
                          <Badge utils={{ bgColor: getBadgeVariant(Number(program?.matchScore?.financingType)) }}>{program?.matchScore?.financingType}%</Badge>
                        </FlexRow>
                        <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>
                          {isMatch
                            ? `This program's capital types include ${dealCapitalType}`
                            : `This program's capital types do not include ${dealCapitalType}`}
                        </Text>
                      </Tooltip>
                    </Col>
                  );
                })()}
              </Row>
            </ListItem>

            <ListItem>
              <Row gutter={3} utils={{ alignItems: 'center' }}>
                <Col span="auto">Deal Types</Col>
                <Col>
                  <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                </Col>
                <Col span="auto">
                  <CappedList list={program!.deal_type?.map((dealType) => DealTypeLabels[dealType])!} maxLength={2} mobile={{ maxLength: 1 }} />
                </Col>
                {(() => {
                  let text = '';
                  if (Number(program!.matchScore!.loanType) === 0) {
                    text = `This program's deal types do not include ${deal?.project?.loanType?.label}`;
                  } else if (Number(program!.matchScore!.loanType) === 100) {
                    text = `This program's deal types include ${deal?.project?.loanType?.label}`;
                  } else {
                    text = `This program's deal type is ${DealTypeLabels[dealTypeMatching[deal?.project?.loanType?.value as DealType]!]}, which includes ${
                      deal?.project?.loanType?.label
                    }, but is too broad to be a 100% match.`;
                  }

                  return (
                    <Col span="auto">
                      <Status
                        icon={getBadgeIcon(Number(program!.matchScore!.loanType))}
                        variant={getBadgeVariant(Number(program!.matchScore!.loanType))}
                        data-tooltip-id="dealType"
                      />
                      <Tooltip id="dealType">
                        <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                          <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                            Deal Types
                          </Heading>
                          <Badge utils={{ bgColor: getBadgeVariant(Number(program!.matchScore!.loanType)) }}>{program!.matchScore!.loanType}%</Badge>
                        </FlexRow>
                        <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>{text}</Text>
                      </Tooltip>
                    </Col>
                  );
                })()}
              </Row>
            </ListItem>

            <ListItem>
              <Row gutter={3} utils={{ alignItems: 'center' }}>
                <Col span="auto">Loan</Col>
                <Col>
                  <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                </Col>
                <Col span="auto">{getProgramLoanValue(program!)}</Col>
                {(() => {
                  let isMatch = false;
                  if (program?.loan_value_max) {
                    isMatch = dealLoanValue <= program?.loan_value_max;
                  }

                  if (isMatch && program?.loan_value_min) {
                    isMatch = dealLoanValue >= program?.loan_value_min;
                  }

                  let matchText = isMatch
                    ? `This program's loan range includes ${formatMoney(dealLoanValue)}`
                    : `This program's loan range does not include ${formatMoney(dealLoanValue)}`;

                  const isSomeRangeBoundsMissing = !program?.loan_value_min || !program?.loan_value_max;
                  if (isSomeRangeBoundsMissing) {
                    matchText = `This program's loan range includes ${formatMoney(dealLoanValue)}, but is too broad to be a 100% match`;
                  }

                  return (
                    <Col span="auto">
                      <Status
                        icon={getBadgeIcon(Number(program?.matchScore?.loanValue))}
                        variant={getBadgeVariant(Number(program?.matchScore?.loanValue))}
                        data-tooltip-id="loanRange"
                      />
                      <Tooltip id="loanRange">
                        <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                          <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                            Loan
                          </Heading>
                          <Badge utils={{ bgColor: getBadgeVariant(Number(program?.matchScore?.loanValue)) }}>{program?.matchScore?.loanValue}%</Badge>
                        </FlexRow>
                        <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>
                          {program?.loan_value_min || program?.loan_value_max ? matchText : "This program's Loan Range is unknown"}
                        </Text>
                      </Tooltip>
                    </Col>
                  );
                })()}
              </Row>
            </ListItem>

            {getProgramRateValue(program!) && getProgramRateValue(program!) !== '--' && (
              <ListItem>
                <Row gutter={3} utils={{ alignItems: 'center' }}>
                  <Col span="auto">Rate</Col>
                  <Col>
                    <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                  </Col>
                  <Col span="auto">{getProgramRateValue(program!)}</Col>
                  <Col span="auto">
                    <Status icon="info" variant="gray500" data-tooltip-id="rateRange" />
                    <Tooltip id="rateRange">
                      <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                        <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                          Rate
                        </Heading>
                      </FlexRow>
                      <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>Rate isn't used for matching</Text>
                    </Tooltip>
                  </Col>
                </Row>
              </ListItem>
            )}

            {getProgramOriginationFeeValue(program!) && getProgramOriginationFeeValue(program!) !== '--' && (
              <ListItem>
                <Row gutter={3} utils={{ alignItems: 'center' }}>
                  <Col span="auto">Origination Fee</Col>
                  <Col>
                    <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                  </Col>
                  <Col span="auto">{getProgramOriginationFeeValue(program!)}</Col>
                  <Col span="auto">
                    <Status icon="info" variant="gray500" data-tooltip-id="originationFeeRange" />
                    <Tooltip id="originationFeeRange">
                      <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                        <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                          Origination Fee
                        </Heading>
                      </FlexRow>
                      <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>Origination Fee isn't used for matching</Text>
                    </Tooltip>
                  </Col>
                </Row>
              </ListItem>
            )}

            {getProgramExitFeeValue(program!) && getProgramExitFeeValue(program!) !== '--' && (
              <ListItem>
                <Row gutter={3} utils={{ alignItems: 'center' }}>
                  <Col span="auto">Exit Fee</Col>
                  <Col>
                    <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                  </Col>
                  <Col span="auto">{getProgramExitFeeValue(program!)}</Col>
                  <Col span="auto">
                    <Status icon="info" variant="gray500" data-tooltip-id="exitFeeRange" />
                    <Tooltip id="exitFeeRange">
                      <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                        <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                          Exit Fee
                        </Heading>
                      </FlexRow>
                      <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>Exit Fee isn't used for matching</Text>
                    </Tooltip>
                  </Col>
                </Row>
              </ListItem>
            )}

            {getProgramOtherFeesValue(program!) && getProgramOtherFeesValue(program!) !== '--' && (
              <ListItem>
                <Row gutter={3} utils={{ alignItems: 'center' }}>
                  <Col span="auto">Process/Other Fees</Col>
                  <Col>
                    <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                  </Col>
                  <Col span="auto">{getProgramOtherFeesValue(program!)}</Col>
                  <Col span="auto">
                    <Status icon="info" variant="gray500" data-tooltip-id="otherFeesRange" />
                    <Tooltip id="otherFeesRange">
                      <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                        <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                          Process/Other Fees
                        </Heading>
                      </FlexRow>
                      <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>Process/Other Fees isn't used for matching</Text>
                    </Tooltip>
                  </Col>
                </Row>
              </ListItem>
            )}

            {program?.amortization && (
              <ListItem>
                <Row gutter={3} utils={{ alignItems: 'center' }}>
                  <Col span="auto">Amortization</Col>
                  <Col>
                    <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                  </Col>
                  <Col span="auto">{programAmortizationLabels[program.amortization]}</Col>
                  <Col span="auto">
                    <Status icon="info" variant="gray500" data-tooltip-id="amortization" />
                    <Tooltip id="amortization">
                      <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                        <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                          Amortization
                        </Heading>
                      </FlexRow>
                      <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>Amortization isn't used for matching</Text>
                    </Tooltip>
                  </Col>
                </Row>
              </ListItem>
            )}

            {program?.recourses?.length ? (
              <ListItem>
                <Row gutter={3} utils={{ alignItems: 'center' }}>
                  <Col span="auto">Recourse</Col>
                  <Col>
                    <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                  </Col>
                  <Col span="auto">
                    <ProgramRecourses recourses={program.recourses} />
                  </Col>
                  <Col span="auto">
                    <Status icon="info" variant="gray500" data-tooltip-id="recourse" />
                    <Tooltip id="recourse">
                      <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                        <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                          Recourse
                        </Heading>
                      </FlexRow>
                      <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>Recourse isn't used for matching</Text>
                    </Tooltip>
                  </Col>
                </Row>
              </ListItem>
            ) : null}

            {getProgramTermValue(program!) && getProgramTermValue(program!) !== '--' && (
              <ListItem>
                <Row gutter={3} utils={{ alignItems: 'center' }}>
                  <Col span="auto">Term</Col>
                  <Col>
                    <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                  </Col>
                  <Col span="auto">{getProgramTermValue(program!)}</Col>
                  <Col span="auto">
                    <Status icon="info" variant="gray500" data-tooltip-id="termRange" />
                    <Tooltip id="termRange">
                      <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                        <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                          Term
                        </Heading>
                      </FlexRow>
                      <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>Term isn't used for matching</Text>
                    </Tooltip>
                  </Col>
                </Row>
              </ListItem>
            )}

            {program?.dscr && (
              <ListItem>
                <Row gutter={3} utils={{ alignItems: 'center' }}>
                  <Col span="auto">DSCR</Col>
                  <Col>
                    <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                  </Col>
                  <Col span="auto">{`> ${program.dscr}x`}</Col>
                  <Col span="auto">
                    <Status icon="info" variant="gray500" data-tooltip-id="dscr" />
                    <Tooltip id="dscr">
                      <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                        <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                          DSCR
                        </Heading>
                      </FlexRow>
                      <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>DSCR isn't used for matching</Text>
                    </Tooltip>
                  </Col>
                </Row>
              </ListItem>
            )}

            <ListItem>
              <Row gutter={3} utils={{ alignItems: 'center' }}>
                <Col span="auto">LTC</Col>
                <Col>
                  <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                </Col>
                <Col span="auto">{program?.ltc_max ? `< ${program.ltc_max}%` : '--'}</Col>
                {(() => {
                  const dealLtcMax = dealLTC;

                  let tooltipText = program?.ltc_max
                    ? `This program's LTC is ${dealLtcMax < program?.ltc_max ? 'above' : 'below'} the deal's ${dealLtcMax}% LTC`
                    : "This program's LTC is unknown";

                  if (program?.ltc_max && dealLtcMax === program?.ltc_max) {
                    tooltipText = `The program's LTC perfectly matches the deal's ${dealLtcMax}% LTC`;
                  }

                  return (
                    <Col span="auto">
                      <Status
                        icon={getBadgeIcon(Number(program?.matchScore?.LTC))}
                        variant={getBadgeVariant(Number(program?.matchScore?.LTC))}
                        data-tooltip-id="ltcMax"
                      />
                      <Tooltip id="ltcMax">
                        <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                          <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                            LTC Max
                          </Heading>
                          <Badge utils={{ bgColor: getBadgeVariant(Number(program?.matchScore?.LTC)) }}>{program?.matchScore?.LTC}%</Badge>
                        </FlexRow>
                        <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>{tooltipText}</Text>
                      </Tooltip>
                    </Col>
                  );
                })()}
              </Row>
            </ListItem>

            <ListItem>
              <Row gutter={3} utils={{ alignItems: 'center' }}>
                <Col span="auto">LTV</Col>
                <Col>
                  <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                </Col>
                <Col span="auto">{program?.ltv_max ? `< ${program.ltv_max}%` : '--'}</Col>
                {(() => {
                  const dealLtvMax = dealLTV;
                  const programLtvMax = program?.ltv_max;

                  let tooltipText = program?.ltv_max
                    ? `This program's LTV is ${dealLtvMax < programLtvMax! ? 'above' : 'below'} the deal's ${dealLtvMax}% LTV`
                    : "This program's LTV is unknown";

                  if (program?.ltv_max && dealLtvMax === program?.ltv_max) {
                    tooltipText = `The program's LTC perfectly matches the deal's ${dealLtvMax}% LTV`;
                  }

                  return (
                    <Col span="auto">
                      <Status
                        icon={getBadgeIcon(Number(program?.matchScore?.LTV))}
                        variant={getBadgeVariant(Number(program?.matchScore?.LTV))}
                        data-tooltip-id="ltvMax"
                      />
                      <Tooltip id="ltvMax">
                        <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                          <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                            LTV Max
                          </Heading>
                          <Badge utils={{ bgColor: getBadgeVariant(Number(program?.matchScore?.LTV)) }}>{program?.matchScore?.LTV}%</Badge>
                        </FlexRow>
                        <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>{tooltipText}</Text>
                      </Tooltip>
                    </Col>
                  );
                })()}
              </Row>
            </ListItem>

            {program?.debt_yield_min && (
              <ListItem>
                <Row gutter={3} utils={{ alignItems: 'center' }}>
                  <Col span="auto">Debt Yield</Col>
                  <Col>
                    <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                  </Col>
                  <Col span="auto">{`> ${program.debt_yield_min}%`}</Col>
                  <Col span="auto">
                    <Status icon="info" variant="gray500" data-tooltip-id="debtYield" />
                    <Tooltip id="debtYield">
                      <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                        <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                          Debt Yield
                        </Heading>
                      </FlexRow>
                      <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>Debt Yield isn't used for matching</Text>
                    </Tooltip>
                  </Col>
                </Row>
              </ListItem>
            )}

            <ListItem>
              <Row gutter={3} utils={{ alignItems: 'center' }}>
                <Col span="auto">Asset Types</Col>
                <Col>
                  <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                </Col>
                <Col span="auto">
                  <CappedList list={program?.asset_types!} maxLength={3} mobile={{ maxLength: 1 }} />
                </Col>
                {(() => {
                  return (
                    <Col span="auto">
                      <Status
                        icon={getBadgeIcon(Number(program?.matchScore?.assetType))}
                        variant={getBadgeVariant(Number(program?.matchScore?.assetType))}
                        data-tooltip-id="assetType"
                      />
                      <Tooltip id="assetType">
                        <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                          <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                            Asset Types
                          </Heading>
                          <Badge utils={{ bgColor: getBadgeVariant(Number(program?.matchScore?.assetType)) }}>{program?.matchScore?.assetType}%</Badge>
                        </FlexRow>
                        <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>
                          {program?.asset_types?.includes('All') && !program?.asset_types?.includes(deal?.project?.assetType?.label!) ? (
                            <>This program's asset type is all assets, which includes {deal?.project?.assetType?.label}, but is too broad to be a 100% match</>
                          ) : (
                            <>
                              This program's asset types {program?.asset_types?.includes(deal?.project?.assetType?.label!) ? 'include' : 'do not include'}{' '}
                              {deal?.project?.assetType?.label}
                            </>
                          )}
                        </Text>
                      </Tooltip>
                    </Col>
                  );
                })()}
              </Row>
            </ListItem>

            <ListItem>
              <Row gutter={3} utils={{ alignItems: 'center' }}>
                <Col span="auto">Locations</Col>
                <Col>
                  <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                </Col>
                <Col span="auto">
                  {lendingStates?.length <= 4 && lendingStates.join(', ')}
                  {lendingStates?.length > 4 && (
                    <>
                      {lendingStates.slice(0, 3).join(', ')} and{' '}
                      <Text
                        as="span"
                        utils={{ textDecoration: 'underline' }}
                        data-tooltip-id="GlobalTooltip"
                        data-tooltip-content={lendingStates.slice(3, lendingStates.length).join(', ')}
                      >
                        {lendingStates.length - 3} others
                      </Text>
                    </>
                  )}
                  {lendingStates?.length === 0 && '--'}
                </Col>
                {(() => {
                  if (lendingStates?.length === 0) {
                    return null;
                  }

                  return (
                    <Col span="auto">
                      <Status
                        icon={getBadgeIcon(Number(program?.matchScore?.location))}
                        variant={getBadgeVariant(Number(program?.matchScore?.location))}
                        data-tooltip-id="locations"
                      />
                      <Tooltip id="locations">
                        <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                          <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                            Locations
                          </Heading>
                          <Badge utils={{ bgColor: getBadgeVariant(Number(program?.matchScore?.location)) }}>{program?.matchScore?.location}%</Badge>
                        </FlexRow>
                        <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>
                          {lendingStates?.includes('Nationwide') && !lendingStates?.includes(dealState) ? (
                            <>This program's location is Nationwide, which includes {dealState}, but is too broad to be a 100% match</>
                          ) : (
                            <>
                              This program's locations {lendingStates.includes(dealState) ? 'include' : 'do not include'} {dealState}
                            </>
                          )}
                        </Text>
                      </Tooltip>
                    </Col>
                  );
                })()}
              </Row>
            </ListItem>
          </List>
        </>
      )}

      {program?.attachments?.length! > 0 && (
        <>
          <Heading as="h5" utils={{ fontSize: '2xs', textTransform: 'uppercase', color: 'gray700', mt: '-6', mb: 3 }}>
            Attachments:
          </Heading>
          <Text utils={{ fontSize: 'sm', color: 'gray700', mb: 10 }}>
            {program?.attachments!.map((attachment, index) => (
              <Fragment key={attachment.s3Key}>
                <Text
                  as="a"
                  role="button"
                  href={`${getBaseApiUrl()}/api/lender/file/${attachment.s3Key}?isDownload=true&name=${attachment.name}`}
                  utils={{ color: 'primary' }}
                  target="_blank"
                >
                  {attachment.name}
                </Text>
                {index !== program?.attachments!.length - 1 && ' | '}
              </Fragment>
            ))}
          </Text>
        </>
      )}

      {program?.matchScore?.score! > 0 && !enableBlur && (
        <UpdatedBy user={program!.updatedBy} createdAt={program!.createdAt} updatedAt={program!.updatedAt} containerProps={{ utils: { mt: -6, mb: 10 } }} />
      )}

      {!program?.matchScore?.score && !program?.attachments?.length && (
        <>
          <Block utils={{ mb: 6 }}>
            <Heading as="h3" utils={{ fontSize: 'xl' }}>
              Program Match
            </Heading>
          </Block>
          <NotFound size="xs">
            <Text as="strong">No programs yet.</Text>{' '}
            <Text as="span" utils={{ color: 'gray700' }}>
              This lender doesn’t have any programs yet.
            </Text>
          </NotFound>
        </>
      )}
    </>
  );
}
