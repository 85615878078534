import { useEffect } from 'react';

export default function useClickOutside(ref: React.RefObject<HTMLElement | undefined>, isOpen: boolean, setOpen: (isOpen: boolean) => void) {
  useEffect(() => {
    if (!global.document) {
      return;
    }

    function listener(e: MouseEvent | TouchEvent) {
      const target = e.target as HTMLElement;
      if (!ref.current || ref.current.contains(target) || target.classList.contains('react-select__clear-indicator')) {
        return;
      }
      setOpen(false);
    }

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, setOpen]);
}
