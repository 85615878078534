import { DealType } from '~/generated/graphql';

export const dealTypeMatching: Partial<Record<DealType, DealType>> = {
  [DealType.BridgeAcquisition]: DealType.Acquisition,
  [DealType.PermanentAcquisition]: DealType.Acquisition,
  [DealType.BridgeRefinance]: DealType.Refinance,
  [DealType.PermanentRefinance]: DealType.Refinance,
  [DealType.LandDevelopment]: DealType.Development,
  [DealType.GroundUpDevelopment]: DealType.Development,
  [DealType.ValueAddDevelopment]: DealType.Development,
};

export const DealTypeLabels: Record<DealType, string> = {
  [DealType.Acquisition]: 'Acquisition',
  [DealType.Refinance]: 'Refinance',
  [DealType.Development]: 'Development',
  [DealType.BridgeAcquisition]: 'Bridge Acquisition',
  [DealType.PermanentAcquisition]: 'Permanent Acquisition',
  [DealType.BridgeRefinance]: 'Bridge Refinance',
  [DealType.PermanentRefinance]: 'Permanent Refinance',
  [DealType.LandDevelopment]: 'Land Development',
  [DealType.GroundUpDevelopment]: 'Ground Up Development',
  [DealType.ValueAddDevelopment]: 'Value Add Development',
};

type DealTypeOption = {
  value: DealType;
  label: string;
};

export const dealTypeOptionsMap = Object.entries(DealTypeLabels)
  .reduce((acc, [value, label]) => {
    acc[value as DealType] = {
      value: value as DealType,
      label,
    };
    return acc;
  }, {} as Record<DealType, DealTypeOption>);

export const dealTypeOptions = [
  dealTypeOptionsMap[DealType.BridgeAcquisition],
  dealTypeOptionsMap[DealType.PermanentAcquisition],
  dealTypeOptionsMap[DealType.BridgeRefinance],
  dealTypeOptionsMap[DealType.PermanentRefinance],
  dealTypeOptionsMap[DealType.LandDevelopment],
  dealTypeOptionsMap[DealType.GroundUpDevelopment],
  dealTypeOptionsMap[DealType.ValueAddDevelopment],
];

export const dealTypeGroupedOptions: ({ label: string, options: DealTypeOption[] })[] = [{
  label: 'Basic types',
  options: [
    dealTypeOptionsMap[DealType.Acquisition],
    dealTypeOptionsMap[DealType.Refinance],
    dealTypeOptionsMap[DealType.Development],
  ],
}, {
  label: 'Subtypes',
  options: dealTypeOptions,
}];
