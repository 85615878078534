import styled, { css } from 'styled-components';

type EditorToolbarButtonProps = {
  isActive?: boolean;
};

const EditorToolbarButton = styled.button.attrs<EditorToolbarButtonProps>(() => ({ type: 'button' }))<EditorToolbarButtonProps>`
  ${({ isActive, theme }) => css`
    --editor-toolbar-button-size: ${theme.editorToolbarButtonSize}px;
    --editor-toolbar-button-font-size: ${theme.editorToolbarButtonFontSize}px;
    --editor-toolbar-button-bg: ${theme.editorToolbarButtonBg};
    --editor-toolbar-button-border-radius: ${theme.editorToolbarButtonBorderRadius}px;
    --editor-toolbar-button-color: ${theme.editorToolbarButtonColor};
    --editor-toolbar-button-hover-color: ${theme.editorToolbarButtonHoverColor};
    --editor-toolbar-button-active-color: ${theme.editorToolbarButtonActiveColor};
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: var(--editor-toolbar-button-size);
    height: var(--editor-toolbar-button-size);
    border-width: 0;
    border-radius: var(--editor-toolbar-button-border-radius);
    background-color: var(--editor-toolbar-button-bg);
    font-size: var(--editor-toolbar-button-font-size);
    color: var(--editor-toolbar-button-color);
    appearance: none;
    cursor: pointer;

    &:hover {
      color: var(--editor-toolbar-button-hover-color);
    }

    /* Active */

    ${isActive &&
    `
      color: var(--editor-toolbar-button-active-color) !important;
    `}
  `}
`;

export default EditorToolbarButton;
