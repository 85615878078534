import React, { createContext, FC, ReactNode, useMemo } from 'react';

type EditorContextType = {
  onUploadStart?: () => void;
  onUploadEnd?: () => void;
  onUploadError?: (error: string) => void;
};

export const EditorContext = createContext<EditorContextType>({});

type EditorContextProviderProps = EditorContextType & {
  children: ReactNode;
};

const EditorContextProvider: FC<EditorContextProviderProps> = ({ children, onUploadStart, onUploadEnd, onUploadError }) => {
  const context = useMemo(() => ({ onUploadStart, onUploadEnd, onUploadError }), [onUploadStart, onUploadEnd, onUploadError]);

  return <EditorContext.Provider value={context}>{children}</EditorContext.Provider>;
};

export default EditorContextProvider;
