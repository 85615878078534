import { statesMap } from '~/helpers/statesMap';

export const sortedStates = [...Object.entries(statesMap).map(([value, label]) => ({ value, label })), { value: 'Canada', label: 'Canada' }].sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
});

export const statesOptions = Object.entries(statesMap)
  .map(([value, label]) => ({ value, label }))
  .sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

const lendingStatesOptions = [{ value: 'Nationwide', label: 'Nationwide' }, ...sortedStates];

export default lendingStatesOptions;
