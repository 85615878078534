import { ProgramRecourse } from '~/generated/graphql';

export const programRecourseLabels: Record<ProgramRecourse, string> = {
  [ProgramRecourse.FullRecourse]: 'Full Recourse',
  [ProgramRecourse.PartialRecourse]: 'Partial Recourse',
  [ProgramRecourse.NonRecourse]: 'Non-Recourse',
};

export const programRecoursesLabels: Record<ProgramRecourse, string> = {
  [ProgramRecourse.FullRecourse]: 'Full',
  [ProgramRecourse.PartialRecourse]: 'Partial',
  [ProgramRecourse.NonRecourse]: 'Non',
};

const programRecourseOptions: { value: ProgramRecourse; label: string }[] = Object.entries(programRecourseLabels).map(([value, label]) => ({
  value: value as ProgramRecourse,
  label,
}));

export default programRecourseOptions;
