import React, { FC, useEffect } from 'react';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewWrapper } from '@tiptap/react';
import dynamic from 'next/dynamic';

const ImageZoom = dynamic(() => import('~/components/ui/ImageZoom'), { ssr: false });

function getImageSrcSet(src: string, width: number, ratio: number, maxWidth: number) {
  const imageWidth_1x = Math.min(width, maxWidth);
  const imageWidth_2x = width >= maxWidth * 2 ? maxWidth * 2 : null;
  const imageHeight_1x = Math.round(ratio * imageWidth_1x);
  const imageHeight_2x = imageWidth_2x ? Math.round(ratio * imageWidth_2x) : null;
  const imageSrc = imageWidth_2x ? `${src}?width=${imageWidth_2x}&height=${imageHeight_2x}` : `${src}?width=${imageWidth_1x}&height=${imageHeight_1x}`;
  const imageSrcSet = imageWidth_2x
    ? `${src}?width=${imageWidth_1x}&height=${imageHeight_1x} 1x, ${src}?width=${imageWidth_2x}&height=${imageHeight_2x} 2x`
    : `${src}?width=${imageWidth_1x}&height=${imageHeight_1x}`;
  return { imageWidth_1x, imageHeight_1x, imageSrc, imageSrcSet };
}

const TipTapImageZoom: FC<NodeViewProps> = ({ editor, node, updateAttributes, deleteNode }) => {
  const isReadOnly = !editor.options.editable;

  const { src, alt, resolver, ...attrs } = node.attrs;
  const enableZoom = isReadOnly;

  useEffect(() => {
    if (resolver?.then) {
      resolver
        .then((src: string) => {
          updateAttributes({ src, resolver: undefined });
        })
        .catch(() => {
          deleteNode();
        });
    }
  }, [resolver]);

  if (src.startsWith('data:')) {
    return (
      <NodeViewWrapper {...attrs}>
        <img src={src} alt={alt} />
      </NodeViewWrapper>
    );
  }

  const [imageSrc, imageMeta] = src.split('?');
  const [imageWidth, imageHeight] = imageMeta.replace(/(width=)|(height=)/g, '').split('&');
  const imageRatio = imageHeight / imageWidth;

  const {
    imageWidth_1x: imageWidth900,
    imageHeight_1x: imageHeight900,
    imageSrc: imageSrc900,
    imageSrcSet: imageSrcSet900,
  } = getImageSrcSet(imageSrc, imageWidth, imageRatio, 900);

  const {
    imageWidth_1x: imageWidth1920,
    imageHeight_1x: imageHeight1920,
    imageSrc: imageSrc1920,
    imageSrcSet: imageSrcSet1920,
  } = getImageSrcSet(imageSrc, imageWidth, imageRatio, 1920);

  return (
    <NodeViewWrapper {...attrs}>
      {enableZoom ? (
        <ImageZoom
          zoomedWidth={imageWidth1920}
          zoomedHeight={imageHeight1920}
          zoomedSrcSet={imageSrcSet1920}
          zoomedSrc={imageSrc1920}
          srcSet={imageSrcSet900}
          src={imageSrc900}
          alt={alt}
          width={imageWidth900}
          height={imageHeight900}
        />
      ) : (
        <img src={imageSrc900} alt={alt} data-drag-handle />
      )}
    </NodeViewWrapper>
  );
};

export default TipTapImageZoom;
