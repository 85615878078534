import { FC } from 'react';
import { programRecourseLabels, programRecoursesLabels } from '~/data/programRecourseOptions';
import { ProgramRecourse } from '~/generated/graphql';

type ProgramRecoursesProps = {
  recourses: ProgramRecourse[];
};

const ProgramRecourses: FC<ProgramRecoursesProps> = ({ recourses }) => {
  if (recourses.length === 1) {
    return programRecourseLabels[recourses[0]];
  }

  return Object.keys(programRecoursesLabels)
    .filter((key) => recourses.includes(key as ProgramRecourse))
    .map((key) => programRecoursesLabels[key as ProgramRecourse])
    .join('/') + ' Recourse';
};

export default ProgramRecourses;
