import { StatusProps } from '~/components/ui/Status';
import { DealLenderStatus } from '~/generated/graphql';

export type DealLenderPassingContactsOptionValue = 'nothing' | 'unsubscribe' | 'remove';

export type DealLenderPassingContactsOption = {
  value: DealLenderPassingContactsOptionValue;
  label: string;
};

export const DealLenderStatusLabels: Record<DealLenderStatus, string> = {
  [DealLenderStatus.NoAnswer]: 'Shortlisted',
  [DealLenderStatus.Invited]: 'Invited',
  [DealLenderStatus.Interested]: 'Interested',
  [DealLenderStatus.Quoted]: 'Quoted',
  [DealLenderStatus.Closed]: 'Closed',
  [DealLenderStatus.Maybe]: 'Maybe',
  [DealLenderStatus.Passed]: 'Passed',
  [DealLenderStatus.NotResponsive]: 'Not Responsive',
  [DealLenderStatus.NotCompetitive]: 'Not Competitive',
};

export const DealLenderStatusVariants: Record<DealLenderStatus, StatusProps['variant']> = {
  [DealLenderStatus.NoAnswer]: 'circle-dashed',
  [DealLenderStatus.Invited]: 'primary',
  [DealLenderStatus.Interested]: 'primary',
  [DealLenderStatus.Quoted]: 'primary',
  [DealLenderStatus.Closed]: 'success',
  [DealLenderStatus.Maybe]: 'warning',
  [DealLenderStatus.Passed]: 'danger',
  [DealLenderStatus.NotResponsive]: 'gray600',
  [DealLenderStatus.NotCompetitive]: 'gray600',
};

export const DealLenderStatusIcons: Record<DealLenderStatus, StatusProps['icon']> = {
  [DealLenderStatus.NoAnswer]: null,
  [DealLenderStatus.Invited]: null,
  [DealLenderStatus.Interested]: null,
  [DealLenderStatus.Quoted]: null,
  [DealLenderStatus.Closed]: 'check',
  [DealLenderStatus.Maybe]: 'minus',
  [DealLenderStatus.Passed]: 'x',
  [DealLenderStatus.NotResponsive]: 'ellipsis',
  [DealLenderStatus.NotCompetitive]: 'x',
};

export const DealLenderStatusProgress: Record<DealLenderStatus, StatusProps['progress']> = {
  [DealLenderStatus.NoAnswer]: null,
  [DealLenderStatus.Invited]: Math.round((100 / 4) * 1),
  [DealLenderStatus.Interested]: Math.round((100 / 4) * 2),
  [DealLenderStatus.Quoted]: Math.round((100 / 4) * 3),
  [DealLenderStatus.Closed]: null,
  [DealLenderStatus.Maybe]: null,
  [DealLenderStatus.Passed]: null,
  [DealLenderStatus.NotResponsive]: null,
  [DealLenderStatus.NotCompetitive]: null,
};

export const dealLenderStatusOptions: ({ value: DealLenderStatus; label: string } & StatusProps)[] = Object.entries(DealLenderStatusLabels).map(
  ([value, label]) => ({
    value: value as DealLenderStatus,
    label,
    variant: DealLenderStatusVariants[value as DealLenderStatus],
    icon: DealLenderStatusIcons[value as DealLenderStatus],
    progress: DealLenderStatusProgress[value as DealLenderStatus],
  }),
);

export const dealLenderPassingContactsOptions: DealLenderPassingContactsOption[] = [
  { value: 'nothing', label: 'Nothing' },
  { value: 'unsubscribe', label: 'Unsubscribe from automated emails' },
  { value: 'remove', label: 'Remove' },
];
