import { Input } from '~/components/form';
import { InputProps } from '~/components/form/Input/Input';
import styled, { css } from 'styled-components';
import validateUrl from '~/helpers/validateUrl';
import { useEffect } from 'react';

type StyledInputProps = InputProps & {
  isInvalid: boolean;
};

const StyledInput = styled(Input)<StyledInputProps>`
  ${({ isInvalid, theme }) => css`
    --editor-toolbar-link-input-width: ${theme.editorToolbarLinkInputWidth}px;
    --editor-toolbar-link-input-color: ${isInvalid ? theme.colors.danger : null};
    width: var(--editor-toolbar-link-input-width);
    color: var(--editor-toolbar-link-input-color) !important;
    transition: none;
  `}
`;

type EditorToolbarLinkInputProps = {
  linkUrl: string;
  setLinkUrl: (linkUrl: string) => void;
  isLinkUrlInvalid: boolean;
  setIsLinkUrlInvalid: (bool: boolean) => void;
  setIsLinkUrlInputFocused?: (bool: boolean) => void;
  onLink: (url: string) => void;
};

export default function EditorToolbarLinkInput({
  linkUrl,
  setLinkUrl,
  isLinkUrlInvalid,
  setIsLinkUrlInvalid,
  setIsLinkUrlInputFocused,
  onLink,
}: EditorToolbarLinkInputProps) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setLinkUrl(event.target.value);

    if (isLinkUrlInvalid && validateUrl(event.target.value)) {
      setIsLinkUrlInvalid(false);
    }
  }

  function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (validateUrl(linkUrl)) {
        onLink(linkUrl);
      } else {
        setIsLinkUrlInvalid(true);
      }
    }
  }

  useEffect(() => {
    setIsLinkUrlInputFocused?.(true);

    return () => {
      setIsLinkUrlInputFocused?.(false);
    };
  });

  return (
    <StyledInput
      form="#"
      placeholder="Enter a URL then press Enter"
      value={linkUrl}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      autoFocus
      isFlush
      isInvalid={isLinkUrlInvalid}
    />
  );
}
