import { getBaseApiUrl } from '~/helpers/getBaseUrl';
import getStreetViewImage from '../getStreetViewImage';

type gerDealImageSrcParams = {
  width: number;
  height: number;
  lat?: number | null;
  lng?: number | null;
  coverageImageFileId?: string | null;
  coverageImageCrop?: { x?: number | null; y?: number | null; width?: number | null; height?: number | null } | null;
};

export default function getDealImageSrc({ width, height, coverageImageFileId, coverageImageCrop, lat, lng }: gerDealImageSrcParams) {
  const hasCoverImage = !!(coverageImageFileId && coverageImageCrop);
  const hasStreetView = !!(lat && lng);
  const src = hasCoverImage
    ? `${getBaseApiUrl()}/api/images/${coverageImageFileId}?width=${width}&height=${height}&crop=[${coverageImageCrop?.x},${coverageImageCrop?.y},${
        coverageImageCrop?.width
      },${coverageImageCrop?.height}]`
    : hasStreetView
    ? getStreetViewImage({ lat, lng, width, height })
    : null;

  return { src, hasCoverImage, hasStreetView };
}
