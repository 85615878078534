import React, { FC } from 'react';
import { FlexRow } from '~/components/layout';
import { BaseProps } from '~/components/layout/Base';
import { Text } from '~/components/type';
import UserAvatar, { UserData, getUserName } from '~/components/ui/Avatar/UserAvatar';
import { formatDate, formatName } from '~/helpers';

type UpdatedByProps = UserData & {
  createdAt?: string | Date;
  updatedAt?: string | Date;
  containerProps?: BaseProps;
};

const UpdatedBy: FC<UpdatedByProps> = ({ createdAt, updatedAt, containerProps, ...props }) => {
  const fullName = getUserName(props);

  if (!fullName) {
    return null;
  }

  return (
    <FlexRow {...containerProps}>
      <UserAvatar size="2xs" utils={{ mr: 3 }} {...props} />
      <Text utils={{ color: 'gray800', fontSize: 'sm' }}>
        {formatName(fullName, { initialLastName: true })}
        {updatedAt && ` updated ${formatDate(updatedAt)}`}
        {!updatedAt && createdAt && ` created ${formatDate(createdAt)}`}
      </Text>
    </FlexRow>
  );
};

export default UpdatedBy;
