import React, { FC } from 'react';
import { FlexRow } from '~/components/layout';
import { Text } from '~/components/type';
import { Avatar, AvatarGroup } from '~/components/ui';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';
import { LenderContactFragment } from '~/generated/graphql';
import { formatName } from '~/helpers';

type LenderDealContactsProps = {
  contacts: LenderContactFragment[];
};

const LenderDealContacts: FC<LenderDealContactsProps> = ({ contacts }) => {
  const [contact, ...rest] = contacts;

  const plusNText = rest.length > 1 ? rest.map((c) => formatName(`${c.first_name} ${c.last_name}`, { initialLastName: true })).join(', ') : '';

  return (
    <FlexRow>
      <AvatarGroup size="2xs" utils={{ mr: 3 }}>
        <UserAvatar lenderContact={contact} />
        {rest.length === 1 && <UserAvatar lenderContact={rest[0]} />}
        {rest.length > 1 && <Avatar alt={`+ ${rest.length}`} data-tooltip-id="GlobalTooltip" data-tooltip-content={plusNText} style={{ cursor: 'pointer' }} />}
      </AvatarGroup>
      <Text>
        {formatName(`${contact.first_name} ${contact.last_name}`, { initialLastName: true })}
        {rest.length === 1 && (
          <>
            {' & '} {formatName(`${rest[0].first_name} ${rest[0].last_name}`, { initialLastName: true })}
          </>
        )}
        {rest.length > 1 && (
          <>
            {' & '}
            <Text
              as="span"
              utils={{ textDecoration: 'underline' }}
              data-tooltip-id="GlobalTooltip"
              data-tooltip-content={plusNText}
              style={{ cursor: 'pointer' }}
            >
              {rest.length} others
            </Text>
          </>
        )}
      </Text>
    </FlexRow>
  );
};

export default LenderDealContacts;
