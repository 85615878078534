import React, { FC, useEffect } from 'react';
import { NodeViewProps } from '@tiptap/core';
import { Card, CardBody } from '~/components/card';
import { FileIcon } from '~/components/file';
import { formatExtension } from '~/helpers';
import { Heading, Text, StretchedLink } from '~/components/type';
import { Icon } from '~/components/vendor';
import { NodeViewWrapper } from '@tiptap/react';
import { Row, Col } from '~/components/layout';
import { x, download } from '~/components/vendor/Icon/icons';

const TipTapFile: FC<NodeViewProps> = ({ editor, node, updateAttributes, deleteNode }) => {
  const isReadOnly = !editor.options.editable;

  const { src, name, isUploading, resolver, ...attrs } = node.attrs;
  const nameWithoutExtension = name?.split('.').slice(0, -1).join('.');
  const extension = name?.split('.').pop();

  useEffect(() => {
    if (resolver?.then) {
      resolver
        .then((src: string) => {
          updateAttributes({ src, isUploading: undefined, resolver: undefined });
        })
        .catch(() => {
          deleteNode();
        });
    }
  }, [resolver]);

  function handleDelete(e: React.MouseEvent) {
    e.preventDefault();
    deleteNode();
  }

  return (
    <NodeViewWrapper className={!isUploading ? 'is-uploaded' : 'is-uploading'} {...attrs}>
      <Card size="sm" style={{ maxWidth: '400px' }} progress={isUploading ? -1 : undefined} data-drag-handle>
        <CardBody utils={{ p: 6 }}>
          <Row gutter={4} utils={{ alignItems: 'center' }}>
            <Col span="auto" mobile={{ display: 'none' }}>
              <FileIcon>{formatExtension(extension, true)}</FileIcon>
            </Col>
            <Col>
              {isReadOnly ? (
                <StretchedLink as="a" utils={{ color: 'black' }} hover={{ color: 'primary' }} href={src} target="_blank">
                  <Heading as="h4" utils={{ fontWeight: 'base', textTruncate: true }} title={nameWithoutExtension}>
                    {nameWithoutExtension}
                  </Heading>
                </StretchedLink>
              ) : (
                <Heading as="h4" utils={{ fontWeight: 'base', textTruncate: true }} title={nameWithoutExtension}>
                  {nameWithoutExtension}
                </Heading>
              )}
              <Text utils={{ fontSize: 'xs', color: 'gray600', textTruncate: true }}>{!isUploading ? extension?.toUpperCase() : 'Uploading...'}</Text>
            </Col>
            <Col span="auto" style={{ position: 'relative', zIndex: 1 }}>
              {!isUploading && !isReadOnly && (
                <>
                  <Icon
                    icon={x}
                    utils={{ fontSize: 'xl', color: 'gray500' }}
                    hover={{ color: 'gray800' }}
                    data-tooltip-id="GlobalTooltip"
                    data-tooltip-content="Remove file"
                    onClick={handleDelete}
                    role="button"
                  />
                </>
              )}
              {isReadOnly && src && (
                <>
                  <a href={src} download={name} target="_blank" rel="noreferrer" data-tooltip-id="GlobalTooltip" data-tooltip-content="Download">
                    <Icon icon={download} utils={{ fontSize: 'xl', color: 'gray500' }} hover={{ color: 'gray800' }} />
                  </a>
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </NodeViewWrapper>
  );
};

export default TipTapFile;
