import { CapitalType } from '~/generated/graphql';

export const CapitalTypeLabels: Record<CapitalType, string> = {
  seniorDebt: 'Senior Debt',
  mezzanine: 'Mezzanine',
  pace: 'PACE',
  preferredEquity: 'Preferred Equity',
  jvEquity: 'JV Equity',
  lpEquity: 'LP Equity',
  cmbs: 'CMBS',
  sba: 'SBA',
  agency: 'Agency',
};

export const CapitalTypeLowerCaseLabels: Record<CapitalType, string> = {
  seniorDebt: 'senior debt',
  mezzanine: 'mezzanine',
  pace: 'PACE',
  preferredEquity: 'preferred equity',
  jvEquity: 'JV equity',
  lpEquity: 'LP equity',
  cmbs: 'CMBS',
  sba: 'SBA',
  agency: 'agency',
};

type CapitalTypeOption = {
  value: CapitalType;
  label: string;
};

export const capitalTypeOptions: ({ value: CapitalType; label: string })[] = Object.entries(CapitalTypeLabels)
  .map(([value, label]) => ({
    value: value as CapitalType,
    label,
  }));

export const capitalTypeOptionsMap = Object.entries(CapitalTypeLabels)
  .reduce((acc, [value, label]) => {
    acc[value as CapitalType] = {
      value: value as CapitalType,
      label,
    };
    return acc;
  }, {} as Record<CapitalType, CapitalTypeOption>);

export default capitalTypeOptions;
