import { bold, checkedList, chevronDown, dotpoints01, edit, heading01, italic, link, orderedList, strikethrough01, trash2, underline } from '../../Icon/icons';
import { DropdownControlled, DropdownItem, DropdownMenu } from '../../Dropdown';
import { Editor } from '@tiptap/core';
import { Text } from '~/components/type';
import { useClick, useMenuState } from '@szhsin/react-menu';
import { useEffect, useRef, useState } from 'react';
import EditorToolbarButton from './EditorToolbarButton';
import EditorToolbarLinkInput from './EditorToolbarLinkInput';
import Icon from '../../Icon';
import styled, { css } from 'styled-components';

export const StyledDropdownMenu = styled(DropdownMenu)`
  ${({ theme }) => css`
    --editor-toolbar-padding-y: ${theme.editorToolbarPaddingY}px;
    --editor-toolbar-padding-x: ${theme.editorToolbarPaddingX}px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    box-sizing: border-box;
    padding: var(--editor-toolbar-padding-y) var(--editor-toolbar-padding-x);

    /* Has buttons */

    &:has(button) {
      --editor-toolbar-padding-y: 0;
      --editor-toolbar-padding-x: 0;
    }
  `}
`;

type EditorToolbarProps = {
  editor: Editor;
};

export default function EditorToolbar({ editor }: EditorToolbarProps) {
  // toolbar
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const isEditorSelectionActive = useRef(false);

  // link input
  const [isToolbarLinkVisible, setIsToolbarLinkVisible] = useState(false);

  // list dropdown
  const listAnchorRef = useRef(null);
  const [listMenuState, toggleListMenu] = useMenuState({ transition: true });
  const listAnchorProps = useClick(listMenuState.state, toggleListMenu);

  // link dropdown
  const linkAnchorRef = useRef(null);
  const [linkMenuState, toggleLinkMenu] = useMenuState({ transition: true });
  const linkAnchorProps = useClick(linkMenuState.state, toggleLinkMenu);

  // link url
  const [linkUrl, setLinkUrl] = useState('');
  const [isLinkUrlInvalid, setIsLinkUrlInvalid] = useState(false);

  // common button props
  const buttonProps = {
    onMouseDown: (e: React.MouseEvent) => {
      e.preventDefault();
    },
  };

  // toggle menu on select
  useEffect(() => {
    const handleSelectionEnd = (e: MouseEvent | KeyboardEvent) => {
      if (e.type === 'mouseup' || (e.type === 'keyup' && ((e as KeyboardEvent).key === 'Shift' || (e as KeyboardEvent).key === 'a'))) {
        removeEventListeners();
        isEditorSelectionActive.current = false;

        const { from, to } = editor.state.selection;
        const range = document.createRange();
        const start = editor.view.domAtPos(from);
        const end = editor.view.domAtPos(to);

        range.setStart(start.node, start.offset);
        range.setEnd(end.node, end.offset);
        const { x, y, width } = range.getBoundingClientRect();

        setAnchorPoint({ x: x + width / 2, y });
        toggleMenu(true);
      }
    };

    const addEventListeners = () => {
      if (!isEditorSelectionActive.current) {
        isEditorSelectionActive.current = true;
        document.addEventListener('keyup', handleSelectionEnd);
        document.addEventListener('mouseup', handleSelectionEnd);
      }
    };

    const removeEventListeners = () => {
      if (isEditorSelectionActive.current) {
        isEditorSelectionActive.current = false;
        document.removeEventListener('keyup', handleSelectionEnd);
        document.removeEventListener('mouseup', handleSelectionEnd);
      }
    };

    editor.on('selectionUpdate', () => {
      const { from, to } = editor.state.selection;

      if (editor.isEditable && !editor.isActive('imageZoom') && !editor.isActive('file')) {
        if (from !== to) {
          addEventListeners();
        } else {
          toggleMenu(false);
          removeEventListeners();
        }
      }
    });

    return () => {
      editor.off('selectionUpdate');
      removeEventListeners();
    };
  }, [editor, toggleMenu]);

  // open link toolbar shortcut
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { from, to } = editor.state.selection;

      if (event.metaKey && event.key === 'k' && from !== to) {
        event.stopPropagation();
        setIsToolbarLinkVisible(true);
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [editor]);

  // cleanup menu state
  useEffect(() => {
    if (menuState.state === 'opening') {
      setLinkUrl('');
      setIsLinkUrlInvalid(false);
      setIsToolbarLinkVisible(false);
    }
  }, [menuState]);

  // add link
  const handleLink = (href: string) => {
    if (!/^http/.test(href)) {
      href = `http://${href}`;
    }
    if (editor) {
      setIsToolbarLinkVisible(false);
      editor.chain().focus().setLink({ href }).run();
    }
  };

  // open link toolbar
  const handleLinkButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (editor) {
      setIsToolbarLinkVisible(true);
    }
  };

  // close menu
  const handleClose = () => {
    toggleMenu(false);
    editor.commands.selectTextblockEnd();
  };

  return (
    <DropdownControlled
      align="center"
      anchorPoint={anchorPoint}
      arrow={true}
      captureFocus={false}
      direction="top"
      gap={-2}
      onClose={handleClose}
      {...menuState}
    >
      <StyledDropdownMenu>
        {isToolbarLinkVisible ? (
          <EditorToolbarLinkInput
            linkUrl={linkUrl}
            setLinkUrl={setLinkUrl}
            isLinkUrlInvalid={isLinkUrlInvalid}
            setIsLinkUrlInvalid={setIsLinkUrlInvalid}
            onLink={handleLink}
          />
        ) : (
          <>
            <EditorToolbarButton
              isActive={editor.isActive('heading')}
              onClick={() => {
                editor.chain().focus().toggleHeading({ level: 1 }).run();
              }}
              {...buttonProps}
            >
              <Icon icon={heading01} />
            </EditorToolbarButton>
            <EditorToolbarButton
              isActive={editor.isActive('bold')}
              onClick={() => {
                editor.chain().focus().toggleBold().run();
              }}
              {...buttonProps}
            >
              <Icon icon={bold} />
            </EditorToolbarButton>
            <EditorToolbarButton
              isActive={editor.isActive('italic')}
              onClick={() => {
                editor.chain().focus().toggleItalic().run();
              }}
              {...buttonProps}
            >
              <Icon icon={italic} />
            </EditorToolbarButton>
            <EditorToolbarButton
              isActive={editor.isActive('underline')}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                editor.chain().focus().toggleUnderline().run();
              }}
              {...buttonProps}
            >
              <Icon icon={underline} />
            </EditorToolbarButton>
            <EditorToolbarButton
              isActive={editor.isActive('strike')}
              onClick={() => {
                editor.chain().focus().toggleStrike().run();
              }}
              {...buttonProps}
            >
              <Icon icon={strikethrough01} />
            </EditorToolbarButton>

            {/* Divider */}
            <Text utils={{ color: 'gray300' }}>|</Text>

            <EditorToolbarButton
              ref={listAnchorRef}
              isActive={editor.isActive('bulletList') || editor.isActive('orderedList') || editor.isActive('checkedList')}
              {...listAnchorProps}
              {...buttonProps}
            >
              <Icon icon={editor.isActive('orderedList') ? orderedList : editor.isActive('checkedList') ? checkedList : dotpoints01} />{' '}
              <Icon icon={chevronDown} utils={{ fontSize: '2xs', color: 'gray500' }} />
            </EditorToolbarButton>

            <DropdownControlled align="center" gap={4} anchorRef={listAnchorRef} onClose={() => toggleListMenu(false)} {...listMenuState}>
              <DropdownMenu>
                <DropdownItem
                  utils={{ color: editor.isActive('bulletList') ? 'primary' : undefined }}
                  onClick={() => {
                    editor.chain().focus().toggleBulletList().run();
                  }}
                  {...buttonProps}
                >
                  <Icon icon={dotpoints01} utils={{ fontSize: 'base' }} style={{ color: 'inherit' }} /> Bulleted List
                </DropdownItem>
                <DropdownItem
                  utils={{ color: editor.isActive('orderedList') ? 'primary' : undefined }}
                  onClick={() => {
                    editor.chain().focus().toggleOrderedList().run();
                  }}
                  {...buttonProps}
                >
                  <Icon icon={orderedList} utils={{ fontSize: 'base' }} style={{ color: 'inherit' }} /> Numbered List
                </DropdownItem>
                <DropdownItem
                  utils={{ color: editor.isActive('checkedList') ? 'primary' : undefined }}
                  onClick={() => {
                    editor.chain().focus().toggleCheckedList().run();
                  }}
                  {...buttonProps}
                >
                  <Icon icon={checkedList} utils={{ fontSize: 'base' }} style={{ color: 'inherit' }} /> Checked List
                </DropdownItem>
              </DropdownMenu>
            </DropdownControlled>

            {/* Divider */}
            <Text utils={{ color: 'gray300' }}>|</Text>

            {editor.isActive('link') ? (
              <>
                <EditorToolbarButton ref={linkAnchorRef} isActive {...linkAnchorProps} {...buttonProps}>
                  <Icon icon={link} />
                  <Icon icon={chevronDown} utils={{ fontSize: '2xs', color: 'gray500' }} />
                </EditorToolbarButton>

                <DropdownControlled align="center" gap={4} anchorRef={linkAnchorRef} onClose={() => toggleLinkMenu(false)} {...linkMenuState}>
                  <DropdownMenu>
                    <DropdownItem
                      icon={edit}
                      onClick={() => {
                        setLinkUrl(editor.getAttributes('link').href);
                        setIsToolbarLinkVisible(true);
                      }}
                      {...buttonProps}
                    >
                      Edit Link
                    </DropdownItem>
                    <DropdownItem
                      icon={trash2}
                      onClick={() => {
                        setLinkUrl('');
                        setIsLinkUrlInvalid(false);
                        editor.chain().focus().unsetLink().run();
                      }}
                      {...buttonProps}
                    >
                      Remove Link
                    </DropdownItem>
                  </DropdownMenu>
                </DropdownControlled>
              </>
            ) : (
              <EditorToolbarButton ref={linkAnchorRef} onClick={handleLinkButtonClick} {...buttonProps}>
                <Icon icon={link} />
              </EditorToolbarButton>
            )}
          </>
        )}
      </StyledDropdownMenu>
    </DropdownControlled>
  );
}
