import abbreviateMoney from '~/helpers/abbreviateMoney';

export function getProgramOtherFeesValue(program: { other_fees_min?: number | null; other_fees_max?: number | null }): string {
  if (program?.other_fees_max && program?.other_fees_min) {
    return `${abbreviateMoney(program?.other_fees_min)} - ${abbreviateMoney(program?.other_fees_max)}`;
  }

  if (program?.other_fees_max) {
    return `< ${abbreviateMoney(program?.other_fees_max)}`;
  }

  if (program?.other_fees_min) {
    return `${abbreviateMoney(program?.other_fees_min)} +`;
  }

  return '--';
}
