import React, { FC } from 'react';
import { Heading, Text } from '~/components/type';
import { DealLenderStatus } from '~/generated/graphql';
import { formatDate } from '~/helpers';

type DealLenderReasonProps = {
  status?: DealLenderStatus | null;
  statusUpdatedAt?: Date | null;
  interestedStatusReason?: string | null;
  maybeStatusReason?: string | null;
  passedStatusReason?: string | null;
  notCompetitiveStatusReason?: string | null;
};

const DealLenderReason: FC<DealLenderReasonProps> = ({
  status,
  statusUpdatedAt,
  interestedStatusReason,
  maybeStatusReason,
  passedStatusReason,
  notCompetitiveStatusReason,
}) => {
  let title = '';
  let reason = '';

  if (status === DealLenderStatus.Interested && interestedStatusReason) {
    title = 'Reason for Interest';
    reason = interestedStatusReason;
  }
  if (status === DealLenderStatus.Maybe && maybeStatusReason) {
    title = 'Reason for Maybe';
    reason = maybeStatusReason;
  }
  if (status === DealLenderStatus.Passed && passedStatusReason) {
    title = 'Reason for Passing';
    reason = passedStatusReason;
  }
  if (status === DealLenderStatus.NotCompetitive && notCompetitiveStatusReason) {
    title = 'Reason for being Not Competitive';
    reason = notCompetitiveStatusReason;
  }

  if (!title || !reason) {
    return null;
  }

  return (
    <>
      <Heading as="h5" utils={{ fontSize: '2xs', textTransform: 'uppercase', color: status === DealLenderStatus.Passed ? 'danger' : 'gray700', mb: 3 }}>
        {title}
      </Heading>
      <Text utils={{ fontSize: 'sm', mb: 8 }}>
        {reason}
        <Text as="span" utils={{ color: 'gray600', ml: 2 }}>
          {formatDate(statusUpdatedAt)}
        </Text>
      </Text>
    </>
  );
};

export default DealLenderReason;
