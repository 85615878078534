import Base from '~/components/layout/Base';
import { Editor } from '@tiptap/core';
import { FileInput, Button } from '~/components/form';
import { v4 as uuid } from 'uuid';
import { Icon } from '~/components/vendor';
import { paperclip } from '~/components/vendor/Icon/icons';
import { Row, Col } from '~/components/layout';
import styled, { css } from 'styled-components';
import { useMemo } from 'react';

const StyledEditorAddons = styled(Base)`
  ${() => css`
    margin-top: auto;
    padding: 0 var(--editor-padding-x) var(--editor-padding-y);
  `}
`;

type EditorAddonsProps = {
  allowFileAttachments: boolean;
  editor: Editor;
  handleCancel: () => void;
  handleCreate: () => void;
  handleUpload: (editor: Editor, files: File[], index: number) => void;
  handleUpdate: () => void;
  isLoading: boolean;
  onCancel?: () => void;
  onCreate?: (value: string) => void;
  onUpdate?: (value: string) => void;
  toolbarAddons: React.ReactNode;
};

export default function EditorAddons({
  allowFileAttachments,
  editor,
  handleCancel,
  handleCreate,
  handleUpload,
  handleUpdate,
  isLoading,
  onCancel,
  onCreate,
  onUpdate,
  toolbarAddons,
}: EditorAddonsProps) {
  const toolbarId = useMemo(() => uuid(), []);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files) {
      handleUpload(editor, e.target.files as unknown as File[], editor.state.selection.anchor);
    }
  }

  return (
    <StyledEditorAddons>
      <Row gutter={1} utils={{ alignItems: 'center' }}>
        <Col span="auto">
          <FileInput
            inputProps={{
              id: `${toolbarId}ImageUpload`,
              onChange: handleChange,
              accept: allowFileAttachments ? undefined : 'image/*',
              multiple: true,
              form: '#',
            }}
            labelProps={{
              utils: { color: 'gray500' },
              hover: { color: 'primary' },
              style: { cursor: 'pointer' },
            }}
            data-tooltip-id="GlobalTooltip"
            data-tooltip-content={`Add an ${allowFileAttachments ? 'attachment' : 'image'}`}
          >
            <Icon icon={paperclip} />
          </FileInput>
        </Col>
        {toolbarAddons}
        {(onCancel || onUpdate || onCreate) && (
          <Col span="auto" utils={{ ml: 'auto', mr: '-3' }}>
            <Row gutter={1}>
              {onCancel && !isLoading && (
                <Col span="auto">
                  <Button size="xs" variant="white" onClick={handleCancel} type="button">
                    Cancel
                  </Button>
                </Col>
              )}
              {onUpdate && (
                <Col span="auto">
                  <Button size="xs" onClick={handleUpdate} type="button" isLoading={isLoading} disabled={isLoading}>
                    Save
                  </Button>
                </Col>
              )}
              {onCreate && (
                <Col span="auto">
                  <Button size="xs" onClick={handleCreate} type="button" isLoading={isLoading} disabled={isLoading}>
                    Add
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </StyledEditorAddons>
  );
}
