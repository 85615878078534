import { pluralize } from '~/helpers/pluralize';

export function getProgramTermValue(program: { term_months_max?: number | null; term_months_min?: number | null }): string {
  if (program?.term_months_max && program?.term_months_min) {
    return `${program?.term_months_min} - ${program?.term_months_max} months`;
  }

  if (program?.term_months_max) {
    return `< ${program?.term_months_max} ${pluralize(program?.term_months_max, 'month')}`;
  }

  if (program?.term_months_min) {
    return `${program?.term_months_min} + ${pluralize(program?.term_months_min, 'month')}`;
  }

  return '--';
}
