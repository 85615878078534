import { ProgramAmortization } from '~/generated/graphql';

export const programAmortizationLabels: Record<ProgramAmortization, string> = {
  INTEREST_ONLY: 'Interest Only',
  PARTIAL_INTEREST_ONLY: 'Partial Interest-Only',
  AMORTIZED: 'Amortized',
};

const programAmortizationOptions: { value: ProgramAmortization, label: string }[] = Object
  .entries(programAmortizationLabels)
  .map(([value, label]) => ({ value: value as ProgramAmortization, label }));

export default programAmortizationOptions;
