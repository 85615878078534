import { AssetType } from '~/generated/graphql';

export const AssetTypeLabels: Record<AssetType, string> = {
  [AssetType.SingleFamily]: 'Single Family',
  [AssetType.Multifamily]: 'Multifamily',
  [AssetType.Townhouses]: 'Townhouses',
  [AssetType.Office]: 'Office',
  [AssetType.Retail]: 'Retail',
  [AssetType.Hotel]: 'Hospitality',
  [AssetType.Industrial]: 'Industrial',
  [AssetType.MixedUse]: 'Mixed Use',
  [AssetType.Cannabis]: 'Cannabis',
  [AssetType.SeniorLiving]: 'Senior Living',
  [AssetType.Land]: 'Land',
  [AssetType.SelfStorage]: 'Self Storage',
  [AssetType.StudentHousing]: 'Student Housing',
  [AssetType.MobileHomePark]: 'Mobile Home Park',
  [AssetType.Medical]: 'Medical',
  [AssetType.LifeScience]: 'Life Science',
  [AssetType.Condominiums]: 'Condominiums',
  [AssetType.BuildToRent]: 'Build to Rent',
  [AssetType.Other]: 'Other',
};

const assetTypeOptions: { value: AssetType; label: string }[] = Object.entries(AssetTypeLabels)
  .map(([value, label]) => ({ value: value as AssetType, label }))
  .sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

export default assetTypeOptions;
