import { useMemo } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { AppContext } from 'next/app';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import createApolloClient from './apolloClient';
import isEqual from 'lodash.isequal';
import merge from 'deepmerge';
import { PageProps } from '~/pages/_app';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

interface NextPageContextWithApollo extends NextPageContext {
  apolloClient: ApolloClient<NormalizedCacheObject> | null;
  apolloState: NormalizedCacheObject;
  ctx: NextPageContextApp;
}

type NextPageContextApp = NextPageContextWithApollo & AppContext;

// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export function initializeApollo(initialState?: NormalizedCacheObject, ctx?: NextPageContext | GetServerSidePropsContext) {
  const _apolloClient = globalApolloClient ?? createApolloClient(initialState, ctx);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [...sourceArray, ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!globalApolloClient) globalApolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(pageProps: PageProps) {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}

export function addApolloState(client: ApolloClient<NormalizedCacheObject>) {
  const pageProps = {
    [APOLLO_STATE_PROP_NAME]: client.cache.extract(),
  };

  return pageProps;
}
