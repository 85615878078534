export function getProgramOriginationFeeValue(program: { origination_fee_max?: number | null; origination_fee_min?: number | null }): string {
  if (program?.origination_fee_max && program?.origination_fee_min) {
    return `${program?.origination_fee_min} - ${program?.origination_fee_max}%`;
  }

  if (program?.origination_fee_max) {
    return `< ${program?.origination_fee_max}%`;
  }

  if (program?.origination_fee_min) {
    return `${program?.origination_fee_min}% +`;
  }

  return '--';
}
