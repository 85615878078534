import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Component from './Component';

export default Node.create({
  name: 'imageZoom',
  group: 'block',
  draggable: true,

  addAttributes() {
    return {
      src: {
        default: '',
      },
      alt: {
        default: undefined,
      },
      resolver: {
        default: undefined,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});
