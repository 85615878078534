import abbreviateMoney from '~/helpers/abbreviateMoney';

export function getProgramLoanValue(program: { loan_value_min?: number | null; loan_value_max?: number | null }): string {
  if (program?.loan_value_max && program?.loan_value_min) {
    return `${abbreviateMoney(program?.loan_value_min)} - ${abbreviateMoney(program?.loan_value_max)}`;
  }

  if (program?.loan_value_max) {
    return `< ${abbreviateMoney(program?.loan_value_max)}`;
  }

  if (program?.loan_value_min) {
    return `${abbreviateMoney(program?.loan_value_min)} +`;
  }

  return '--';
}
