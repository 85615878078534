export function getProgramExitFeeValue(program: { exit_fee_max?: number | null; exit_fee_min?: number | null }): string {
  if (program?.exit_fee_max && program?.exit_fee_min) {
    return `${program?.exit_fee_min} - ${program?.exit_fee_max}%`;
  }

  if (program?.exit_fee_max) {
    return `< ${program?.exit_fee_max}%`;
  }

  if (program?.exit_fee_min) {
    return `${program?.exit_fee_min}% +`;
  }

  return '--';
}
