import { useCallback, useEffect, useState } from 'react';

const getValue = <T>(key: string, initialValue: T) => {
  try {
    // Get from local storage by key
    const item = typeof window !== 'undefined' ? window.localStorage.getItem(key) : undefined;
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    // If error also return initialValue
    console.log(error);
    return initialValue;
  }
};

/**
 * Similar to useState but first arg is key to the value in local storage.
 * @param {*} key key to the value
 * @param {*} initialValue
 */
const useLocalStorage = <T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(getValue(key, initialValue));

  useEffect(() => {
    setStoredValue(getValue(key, initialValue));
  }, [key]);

  // Return a wrapped version of useState's setter function that  persists the new value to localStorage.
  const setValue: React.Dispatch<React.SetStateAction<T>> = useCallback((value) => {
    // Save state
    setStoredValue((oldValue) => {
      const valueToStore = value instanceof Function ? value(oldValue) : value;
      try {
        typeof window !== 'undefined' && window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
      return valueToStore;
    });
  }, [key]);

  return [storedValue, setValue];
};

export default useLocalStorage;
