import { RateType } from '~/generated/graphql';

export const RateTypeLabels: Record<RateType, string> = {
  [RateType.FlatRate]: 'Flat Rate',
  [RateType.Sofr]: 'SOFR',
  [RateType.Prime]: 'Prime',
  [RateType.TenYearTreasury]: '10 Year Treasury',
  [RateType.FiveYearTreasury]: '5 Year Treasury',
};

export const rateTypeOptions: ({ value: RateType; label: string })[] = Object.entries(RateTypeLabels)
  .map(([value, label]) => ({
    value: value as RateType,
    label,
  }));

export default rateTypeOptions;
