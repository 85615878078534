import { RateTypeLabels } from '~/data/rateTypeOptions';
import { RateType } from '~/generated/graphql';

export const getQuoteRateValue = (rateType: RateType, rate: number) => {
  if (rateType === RateType.FlatRate) {
    return `${parseFloat(rate.toFixed(4))}%`;
  }
  return `${RateTypeLabels[rateType]} + ${new Intl.NumberFormat('en-US').format(rate * 100)}`;
};

export function getProgramRateValue(program: { rate_type?: RateType | null; rate_min?: number | null; rate_max?: number | null }): string {
  if (!program) {
    return '';
  }

  if (!program.rate_type || program.rate_type === 'FLAT_RATE') {
    if (program.rate_max && program.rate_min) {
      return `${parseFloat(program.rate_min.toFixed(4))} - ${parseFloat(program.rate_max.toFixed(4))}%`;
    }

    if (program.rate_max) {
      return `< ${parseFloat(program.rate_max.toFixed(4))}%`;
    }

    if (program.rate_min) {
      return `${parseFloat(program.rate_min.toFixed(4))}% +`;
    }
  }

  if (program.rate_type) {
    if (program.rate_max && program.rate_min) {
      return `${RateTypeLabels[program.rate_type]} + ${new Intl.NumberFormat('en-US').format(program.rate_min * 100)} - ${new Intl.NumberFormat('en-US').format(program.rate_max * 100)}`;
    }
    if (program.rate_min || program.rate_max) {
      return `${RateTypeLabels[program.rate_type]} + ${new Intl.NumberFormat('en-US').format((program.rate_min || program.rate_max)! * 100)}`;
    }
  }

  return '';
}
