import React from 'react';
import { Base, FlexRow } from '~/components/layout';
import { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import { CustomTheme } from '~/@types/styled-components';
import { Heading, Text } from '~/components/type';
import { Progress, ProgressBar } from '~/components/ui';
import Button, { ButtonProps } from '~/components/form/Button';
import Image, { ImageProps } from '~/components/ui/Image';
import Link from 'next/link';
import styled, { css } from 'styled-components';

export const notFoundImages = {
  mailbox: 'illustration-1',
  desktop: 'illustration-2',
  knitter: 'illustration-3',
  gallery: 'illustration-4',
  racer: 'illustration-5',
  money: 'illustration-6',
  hammock: 'illustration-7',
  nomail: 'illustration-8',
  comeback: 'illustration-9',
  nomoney: 'illustration-10',
};

type StyledNotFoundProps = {
  size?: keyof CustomTheme['notFoundSpacing'];
};

const StyledNotFound = styled(Base)<StyledNotFoundProps>`
  ${({ size = 'base', theme }) => css`
    --not-found-spacing: ${theme.notFoundSpacing[size]}px;
    --not-found-image-max-width: 320px;
    --not-found-heading-font-size: ${theme.notFoundHeadingFontSize[size]}px;
    --not-found-heading-font-weight: ${theme.notFoundHeadingFontWeight[size]};
    --not-found-heading-margin-bottom: ${theme.notFoundHeadingMarginBottom[size]}px;
    --not-found-content-font-size: ${theme.notFoundContentFontSize[size]}px;
    padding: var(--not-found-spacing);
    border: ${theme.borderWidth}px dashed ${theme.colors.gray400};
    border-radius: ${theme.borderRadius.base}px;
    font-size: var(--not-found-content-font-size);
    text-align: center;
  `}
`;

const ImageWithRef = React.forwardRef((props: ImageProps, ref: React.Ref<HTMLImageElement>) => <Image ref={ref} {...props} />);
ImageWithRef.displayName = 'ImageWithRef';

const StyledImage = styled(ImageWithRef)`
  ${() => css`
    max-width: var(--not-found-image-max-width);
  `}
`;

const StyledTitle = styled(Heading)`
  ${() => css`
    font-size: var(--not-found-heading-font-size);
    font-weight: var(--not-found-heading-font-weight);
    margin-bottom: var(--not-found-heading-margin-bottom);
  `}
`;

const StyledDescription = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.gray700};
  `}
`;

const ButtonWithRef = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => <Button ref={ref} {...props} />);
ButtonWithRef.displayName = 'ButtonWithRef';

const StyledButton = styled(ButtonWithRef)`
  ${({ theme }) => css`
    margin-top: ${theme.spacers[6]}px;
  `}
`;

type NotFoundType = {
  <C extends React.ElementType = 'div'>(props: NotFoundProps<C> & { ref?: React.Ref<HTMLDivElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type NotFoundInnerProps = StyledNotFoundProps & {
  button?: string;
  buttonHref?: string;
  buttonProps?: ButtonProps;
  children?: React.ReactNode;
  description?: React.ReactNode;
  descriptionProps?: BaseHOCPropsWithoutRef<'div'>;
  image?: keyof typeof notFoundImages;
  imageProps?: ImageProps;
  isLoading?: boolean;
  progress?: number;
  title?: string;
  titleProps?: BaseHOCPropsWithoutRef<'h1'>;
};

type NotFoundProps<C extends React.ElementType = 'div'> = BaseHOCPropsWithoutRef<C> & NotFoundInnerProps;

const NotFound: NotFoundType = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    {
      as,
      button,
      buttonHref,
      buttonProps = {},
      children,
      description,
      descriptionProps = {},
      image,
      imageProps = {},
      isLoading,
      progress,
      size = 'base',
      title,
      titleProps = {},
      ...props
    }: NotFoundProps<C>,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <StyledNotFound ref={ref} as={as as React.ElementType} size={size} {...props}>
      {children ? (
        children
      ) : (
        <>
          {image && (
            <picture>
              <source srcSet={`/img/illustrations/${notFoundImages[image]}.webp`} type="image/webp" />
              <StyledImage src={`/img/illustrations/${notFoundImages[image]}.png`} alt={title} showImageFallbackColor={false} isFluid {...imageProps} />
            </picture>
          )}

          {/* Heading */}
          {title && <StyledTitle {...titleProps}>{title}</StyledTitle>}

          {/* Description */}
          {description && <StyledDescription {...descriptionProps}>{description}</StyledDescription>}

          {/* Progress */}
          {progress && (
            <FlexRow utils={{ justifyContent: 'center', alignItems: 'center', mt: 6, mb: 7 }}>
              <Progress>
                <ProgressBar value={progress} />
              </Progress>{' '}
              <Text utils={{ ml: 5, fontSize: 'sm', color: 'gray700' }}>{progress}% complete</Text>
            </FlexRow>
          )}

          {/* Button */}
          {button &&
            (buttonHref ? (
              <Link href={buttonHref} legacyBehavior>
                <StyledButton variant="primary" isLoading={isLoading} {...buttonProps}>
                  {button}
                </StyledButton>
              </Link>
            ) : (
              <StyledButton variant="primary" isLoading={isLoading} {...buttonProps}>
                {button}
              </StyledButton>
            ))}
        </>
      )}
    </StyledNotFound>
  ),
);

NotFound.displayName = 'NotFound';
export default NotFound;
