import { GOOGLE_MAPS_API_KEY } from '~/components/vendor/SelectAddress';

type Props = {
  lat?: number | null;
  lng?: number | null;
  width: number;
  height: number;
};

export default function getStreetViewImage({ lat, lng, width, height }: Props): string {
  return lat && lng
    ? `https://maps.googleapis.com/maps/api/streetview?location=${lat},${lng}&size=${Math.round(width)}x${Math.round(
        height,
      )}&key=${GOOGLE_MAPS_API_KEY}&return_error_code=true`
    : '';
}
