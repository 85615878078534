import { BaseProps } from '~/components/layout/Base';
import Avatar, { AvatarInnerProps } from '~/components/ui/Avatar/Avatar';
import getDealImageSrc from '~/helpers/deal/getDealImageSrc';

type DealsAvatarProps = BaseProps &
  AvatarInnerProps & {
    lat?: number | null;
    lng?: number | null;
    coverageImageFileId?: string | null;
    coverageImageCrop?: { x?: number | null; y?: number | null; width?: number | null; height?: number | null } | null;
    name?: string;
  };

export default function DealsAvatar({ lat, lng, coverageImageFileId, coverageImageCrop, name, utils = {}, ...props }: DealsAvatarProps) {
  const { src, hasCoverImage, hasStreetView } = getDealImageSrc({ width: 192, height: 192, coverageImageFileId, coverageImageCrop, lat, lng });

  return <Avatar src={src} alt={name} utils={utils} shouldClipGoogleLogo={!hasCoverImage && hasStreetView} {...props} />;
}
