import React, { FC, Fragment, useMemo } from 'react';
import { Badge, Divider, Status } from '~/components/ui';
import { Block, Col, FlexRow, Row } from '~/components/layout';
import {
  AssetType,
  CapitalType,
  CompanyFragment,
  DealFieldsFragment,
  DealFieldsProjectFragment,
  DealLenderStatus,
  DealType,
  LenderDealsQuery,
  SearchQuoteFragment,
} from '~/generated/graphql';
import { DealLenderStatusIcons, DealLenderStatusLabels, DealLenderStatusProgress, DealLenderStatusVariants } from '~/data/dealLenderStatusOptions';
import { externalLink } from '~/components/vendor/Icon/icons';
import { formatNumbers, getDistance } from '~/helpers';
import { Heading, StretchedLink, Text } from '~/components/type';
import { Icon } from '~/components/vendor';
import { List, ListItem } from '~/components/list';
import { TableRow, TableCell, TableCollapse, TableCollapseBody } from '~/components/table';
import DealsAvatar from '../Deals/DealsAvatar';
import Link from 'next/link';
import { pluralize } from '~/helpers/pluralize';
import { QuoteTypeLabels } from '~/data/quoteTypeOptions';
import { quoteViewModalVar } from '~/lib/apolloClient';
import { BaseProps } from '~/components/layout/Base';
import dynamic from 'next/dynamic';
import { getBadgeIcon, getBadgeVariant } from '~/modals/MatchModal/MatchModalMatch';
import UpdatedBy from '../UpdatedBy';
import LenderDealContacts from './LenderDealContacts';
import DealLenderReason from './DealLenderReason';

const Tooltip = dynamic(() => import('~/components/vendor/Tooltip'), {
  ssr: false,
});

type DealMatchData = {
  name: string;
  assetType?: AssetType | null;
  dealType?: DealType | null;
  capitalType?: CapitalType;
  location: DealFieldsProjectFragment['coordinates'];
  totalRaise: number;
  totalLTC: number;
  totalLTV: number;
};

export const getTotalRaise = (deal: DealFieldsFragment | LenderDealsQuery['lenderDeals']['lenderDeals'][number]['deal'] | SearchQuoteFragment['deal']) => {
  if (deal.settings.closingFeeBreakdown?.amountFinanced) {
    return deal.settings.closingFeeBreakdown?.amountFinanced;
  }
  return deal.financials?.loanRequestAmount ?? 0;
};

export const getTotalLTC = (
  deal:
    | DealFieldsFragment
    | LenderDealsQuery['lenderDeals']['lenderDeals'][number]['deal']
    | LenderDealsQuery['lenderDeals']['lenderDeals'][number]['deal']
    | SearchQuoteFragment['deal'],
  totalRaise?: number,
) => {
  return (totalRaise && deal.financials?.totalCost && Math.round((totalRaise / deal.financials.totalCost) * 1000) / 10) || 0;
};

export const getTotalLTV = (
  deal:
    | DealFieldsFragment
    | LenderDealsQuery['lenderDeals']['lenderDeals'][number]['deal']
    | LenderDealsQuery['lenderDeals']['lenderDeals'][number]['deal']
    | SearchQuoteFragment['deal'],
  totalRaise?: number,
) => {
  return (totalRaise && deal.financials?.completedValue && Math.round((totalRaise / deal.financials.completedValue) * 1000) / 10) || 0;
};

export const getDealMatchData = (deal: DealFieldsFragment): DealMatchData => {
  const totalRaise = getTotalRaise(deal);
  return {
    name: deal.name,
    assetType: deal.project?.assetType?.value,
    dealType: deal.project?.loanType?.value,
    capitalType: deal.financials?.capitalType?.value,
    location: deal.project?.coordinates,
    totalRaise,
    totalLTC: getTotalLTC(deal, totalRaise),
    totalLTV: getTotalLTV(deal, totalRaise),
  };
};

const getMatchingText = (score: number, match: string, nonMatch: string, similarMatch?: string) => {
  if (score === 100) {
    return match;
  }
  if (score === 0 || !similarMatch) {
    return nonMatch;
  }
  return similarMatch;
};

type LenderDealProps = BaseProps & {
  lenderDeal: LenderDealsQuery['lenderDeals']['lenderDeals'][number];
  deal?: DealMatchData;
  lender: CompanyFragment;
  isOverview?: boolean;
  style?: React.CSSProperties;
};

const LenderDeal: FC<LenderDealProps> = ({ lenderDeal, deal, lender, isOverview, style }) => {
  const { passedStatusReason, status } = lenderDeal;

  const [distance, totalRaise, totalRaiseDiff, totalLTV, totalLTVDiff, totalLTC, totalLTCDiff] = useMemo(() => {
    const totalRaise = getTotalRaise(lenderDeal.deal);
    const totalLTV = getTotalLTV(lenderDeal.deal, totalRaise);
    const totalLTC = getTotalLTC(lenderDeal.deal, totalRaise);

    return [
      deal && getDistance({ point1: deal.location, point2: lenderDeal.deal.project?.coordinates }),
      totalRaise,
      deal && ((Math.abs(totalRaise - deal.totalRaise) / Math.max(totalRaise, deal.totalRaise)) * 100).toFixed(1),
      totalLTV,
      deal && Math.abs(totalLTV - deal.totalLTV).toFixed(1),
      totalLTC,
      deal && Math.abs(totalLTC - deal.totalLTC).toFixed(1),
    ];
  }, [lenderDeal, deal]);

  const invitedContacts = useMemo(() => {
    return lender.contacts.filter((contact) => contact.emails.some(({ value }) => lenderDeal.deal.invitedEmails.includes(value)));
  }, [lenderDeal, lender]);

  return (
    <TableCollapse style={style}>
      {(isOpen, setOpen) => (
        <>
          <TableRow isActive={isOpen}>
            <TableCell utils={{ flexWrap: 'nowrap' }}>
              <DealsAvatar
                coverageImageFileId={lenderDeal.deal.project?.coverageImageFileId}
                coverageImageCrop={lenderDeal.deal.project?.coverageImageCrop}
                lat={lenderDeal.deal.project?.coordinates?.lat}
                lng={lenderDeal.deal.project?.coordinates?.lng}
                name={lenderDeal.deal.name}
                utils={{ borderRadius: 'base', mr: 6 }}
                {...(deal && lenderDeal.matchScore?.score
                  ? {
                      badge: `${Math.round(lenderDeal.matchScore.score)}%`,
                      badgeProps: { utils: { bgColor: getBadgeVariant(lenderDeal.matchScore.score) } },
                    }
                  : {})}
              />
              <Block>
                <FlexRow>
                  <StretchedLink onClick={() => setOpen(!isOpen)}>
                    <Heading as="h3" utils={{ fontSize: 'base', fontWeight: 'bold', color: 'black' }}>
                      {lenderDeal.deal.name}
                    </Heading>
                  </StretchedLink>
                </FlexRow>
                <Text utils={{ fontSize: 'sm', color: 'gray700' }}>{lenderDeal.deal.project?.coordinates?.cityState?.replace(/^N\/A, /, '')}</Text>
              </Block>
            </TableCell>
            <TableCell span="auto" style={{ position: 'relative', zIndex: 1, cursor: 'pointer' }}>
              <FlexRow
                utils={{ alignItems: 'center', fontSize: 'sm', color: 'gray800' }}
                data-tooltip-id="GlobalTooltip"
                data-tooltip-content={passedStatusReason}
                data-tooltip-hidden={status !== DealLenderStatus.Passed}
              >
                <Status
                  icon={DealLenderStatusIcons[status]}
                  variant={DealLenderStatusVariants[status]}
                  progress={DealLenderStatusProgress[status]}
                  utils={{ mr: 3 }}
                />
                {DealLenderStatusLabels[status]}
              </FlexRow>
            </TableCell>

            <TableCollapseBody isOpen={isOpen}>
              <TableCell utils={{ position: 'relative' }}>
                <DealLenderReason {...lenderDeal} />
                <FlexRow>
                  {invitedContacts.length > 0 && (
                    <Block utils={{ mr: 12, mb: 7 }}>
                      <Text utils={{ color: 'gray800', textTransform: 'uppercase', fontSize: '2xs', fontWeight: 'bold', mb: 1 }}>Invited</Text>
                      <LenderDealContacts contacts={invitedContacts} />
                    </Block>
                  )}
                  {lenderDeal.deal.quotes.length > 0 && (
                    <Block utils={{ mb: 7 }}>
                      <Text utils={{ color: 'gray800', textTransform: 'uppercase', fontSize: '2xs', fontWeight: 'bold', mb: 1 }}>
                        {pluralize(lenderDeal.deal.quotes.length, 'Quote')}
                      </Text>
                      <Text utils={{ color: 'gray600' }}>
                        {lenderDeal.deal.quotes.map((quote, inx) => (
                          <Fragment key={quote._id}>
                            <Text
                              as="span"
                              utils={{ color: 'primary' }}
                              style={{ cursor: 'pointer' }}
                              onClick={() => quoteViewModalVar({ quotes: lenderDeal.deal.quotes, quote, lender, deal: lenderDeal.deal })}
                              role="button"
                            >
                              {QuoteTypeLabels[quote.type]}
                            </Text>
                            {inx < lenderDeal.deal.quotes.length - 1 && ' | '}
                          </Fragment>
                        ))}
                      </Text>
                    </Block>
                  )}
                </FlexRow>
                <List gutter={3} utils={{ fontSize: 'sm', mb: 0 }}>
                  {lenderDeal.deal.project?.assetType?.label && (
                    <ListItem>
                      <Row gutter={3} utils={{ alignItems: 'center' }}>
                        <Col span="auto" utils={{ fontWeight: 'bold' }}>
                          Asset Type
                        </Col>
                        <Col>
                          <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                        </Col>
                        <Col span="auto">{lenderDeal.deal.project?.assetType?.label}</Col>
                        {deal && (
                          <Col span="auto">
                            <Status
                              icon={getBadgeIcon(Number(lenderDeal.matchScore?.assetType))}
                              variant={getBadgeVariant(Number(lenderDeal.matchScore?.assetType))}
                              data-tooltip-id={`assetType_${lenderDeal.deal._id}`}
                            />
                            <Tooltip id={`assetType_${lenderDeal.deal._id}`}>
                              <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                                <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                                  Asset Type
                                </Heading>
                                <Badge utils={{ bgColor: getBadgeVariant(Number(lenderDeal.matchScore?.assetType)) }}>
                                  {lenderDeal.matchScore?.assetType}%
                                </Badge>
                              </FlexRow>
                              <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>
                                {getMatchingText(
                                  Number(lenderDeal.matchScore?.assetType),
                                  `This deal’s asset type is the same as the ${deal.name} deal.`,
                                  `This deal’s asset type is not the same as the ${deal.name} deal.`,
                                  `This deal’s asset type is not the same as the ${deal.name} deal, but they are similar enough to be a partial match.`,
                                )}
                              </Text>
                            </Tooltip>
                          </Col>
                        )}
                      </Row>
                    </ListItem>
                  )}
                  {lenderDeal.deal.project?.loanType?.label && (
                    <ListItem>
                      <Row gutter={3} utils={{ alignItems: 'center' }}>
                        <Col span="auto" utils={{ fontWeight: 'bold' }}>
                          Deal Type
                        </Col>
                        <Col>
                          <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                        </Col>
                        <Col span="auto">{lenderDeal.deal.project?.loanType?.label}</Col>
                        {deal && (
                          <Col span="auto">
                            <Status
                              icon={getBadgeIcon(Number(lenderDeal.matchScore?.dealType))}
                              variant={getBadgeVariant(Number(lenderDeal.matchScore?.dealType))}
                              data-tooltip-id={`dealType_${lenderDeal.deal._id}`}
                            />
                            <Tooltip id={`dealType_${lenderDeal.deal._id}`}>
                              <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                                <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                                  Deal Type
                                </Heading>
                                <Badge utils={{ bgColor: getBadgeVariant(Number(lenderDeal.matchScore?.dealType)) }}>{lenderDeal.matchScore?.dealType}%</Badge>
                              </FlexRow>
                              <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>
                                {getMatchingText(
                                  Number(lenderDeal.matchScore?.dealType),
                                  `This deal’s deal type is the same as the ${deal.name} deal.`,
                                  `This deal’s deal type is not the same as the ${deal.name} deal.`,
                                  `This deal’s deal type is not the same as the ${deal.name} deal, but they are similar enough to be a partial match.`,
                                )}
                              </Text>
                            </Tooltip>
                          </Col>
                        )}
                      </Row>
                    </ListItem>
                  )}
                  {lenderDeal.deal.financials?.capitalType && (
                    <ListItem>
                      <Row gutter={3} utils={{ alignItems: 'center' }}>
                        <Col span="auto" utils={{ fontWeight: 'bold' }}>
                          Capital Type
                        </Col>
                        <Col>
                          <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                        </Col>
                        <Col span="auto">{lenderDeal.deal.financials?.capitalType?.label}</Col>
                        {deal && (
                          <Col span="auto">
                            <Status
                              icon={getBadgeIcon(Number(lenderDeal.matchScore?.capitalType))}
                              variant={getBadgeVariant(Number(lenderDeal.matchScore?.capitalType))}
                              data-tooltip-id={`capitalType_${lenderDeal.deal._id}`}
                            />
                            <Tooltip id={`capitalType_${lenderDeal.deal._id}`}>
                              <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                                <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                                  Capital Type
                                </Heading>
                                <Badge utils={{ bgColor: getBadgeVariant(Number(lenderDeal.matchScore?.capitalType)) }}>
                                  {lenderDeal.matchScore?.capitalType}%
                                </Badge>
                              </FlexRow>
                              <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>
                                {getMatchingText(
                                  Number(lenderDeal.matchScore?.capitalType),
                                  `This deal’s capital type is the same as the ${deal.name} deal.`,
                                  `This deal’s capital type is not the same as the ${deal.name} deal.`,
                                  `This deal’s capital type is not the same as the ${deal.name} deal, but they are similar enough to be a partial match.`,
                                )}
                              </Text>
                            </Tooltip>
                          </Col>
                        )}
                      </Row>
                    </ListItem>
                  )}
                  <ListItem>
                    <Row gutter={3} utils={{ alignItems: 'center' }}>
                      <Col span="auto" utils={{ fontWeight: 'bold' }}>
                        Location
                      </Col>
                      <Col>
                        <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                      </Col>
                      <Col span="auto">{lenderDeal.deal.project!.coordinates!.cityState!.replace(/^N\/A, /, '')}</Col>
                      {deal && (
                        <Col span="auto">
                          <Status
                            icon={getBadgeIcon(Number(lenderDeal.matchScore?.location))}
                            variant={getBadgeVariant(Number(lenderDeal.matchScore?.location))}
                            data-tooltip-id={`location_${lenderDeal.deal._id}`}
                          />
                          <Tooltip id={`location_${lenderDeal.deal._id}`}>
                            <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                              <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                                Location
                              </Heading>
                              <Badge utils={{ bgColor: getBadgeVariant(Number(lenderDeal.matchScore?.location)) }}>{lenderDeal.matchScore?.location}%</Badge>
                            </FlexRow>
                            <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>
                              {`This deal’s location is ${Math.floor(distance!)} miles from the ${deal.name} deal`}
                            </Text>
                          </Tooltip>
                        </Col>
                      )}
                    </Row>
                  </ListItem>
                  {totalRaise > 0 && (
                    <ListItem>
                      <Row gutter={3} utils={{ alignItems: 'center' }}>
                        <Col span="auto" utils={{ fontWeight: 'bold' }}>
                          Total Raise
                        </Col>
                        <Col>
                          <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                        </Col>
                        <Col span="auto">${formatNumbers(totalRaise)}</Col>
                        {deal && (
                          <Col span="auto">
                            <Status
                              icon={getBadgeIcon(Number(lenderDeal.matchScore?.totalRaise))}
                              variant={getBadgeVariant(Number(lenderDeal.matchScore?.totalRaise))}
                              data-tooltip-id={`totalRaise_${lenderDeal.deal._id}`}
                            />
                            <Tooltip id={`totalRaise_${lenderDeal.deal._id}`}>
                              <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                                <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                                  Total Raise
                                </Heading>
                                <Badge utils={{ bgColor: getBadgeVariant(Number(lenderDeal.matchScore?.totalRaise)) }}>
                                  {lenderDeal.matchScore?.totalRaise}%
                                </Badge>
                              </FlexRow>
                              <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>
                                {`This deal’s total raise is ${totalRaiseDiff}% ${totalRaise > deal.totalRaise ? 'greater' : 'less'} than the ${
                                  deal.name
                                } deal.`}
                              </Text>
                            </Tooltip>
                          </Col>
                        )}
                      </Row>
                    </ListItem>
                  )}
                  {totalLTC > 0 && (
                    <ListItem>
                      <Row gutter={3} utils={{ alignItems: 'center' }}>
                        <Col span="auto" utils={{ fontWeight: 'bold' }}>
                          Total LTC
                        </Col>
                        <Col>
                          <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                        </Col>
                        <Col span="auto">{totalLTC}%</Col>
                        {deal && (
                          <Col span="auto">
                            <Status
                              icon={getBadgeIcon(Number(lenderDeal.matchScore?.totalLTC))}
                              variant={getBadgeVariant(Number(lenderDeal.matchScore?.totalLTC))}
                              data-tooltip-id={`totalLTC_${lenderDeal.deal._id}`}
                            />
                            <Tooltip id={`totalLTC_${lenderDeal.deal._id}`}>
                              <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                                <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                                  Total LTC
                                </Heading>
                                <Badge utils={{ bgColor: getBadgeVariant(Number(lenderDeal.matchScore?.totalLTC)) }}>{lenderDeal.matchScore?.totalLTC}%</Badge>
                              </FlexRow>
                              <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>
                                {`This deal’s total LTC is ${totalLTCDiff}% ${totalLTC > deal.totalLTC ? 'greater' : 'less'} than the ${deal.name} deal.`}
                              </Text>
                            </Tooltip>
                          </Col>
                        )}
                      </Row>
                    </ListItem>
                  )}
                  {totalLTV > 0 && (
                    <ListItem>
                      <Row gutter={3} utils={{ alignItems: 'center' }}>
                        <Col span="auto" utils={{ fontWeight: 'bold' }}>
                          Total LTV
                        </Col>
                        <Col>
                          <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                        </Col>
                        <Col span="auto">{totalLTV}%</Col>
                        {deal && (
                          <Col span="auto">
                            <Status
                              icon={getBadgeIcon(Number(lenderDeal.matchScore?.totalLTV))}
                              variant={getBadgeVariant(Number(lenderDeal.matchScore?.totalLTV))}
                              data-tooltip-id={`totalLTV_${lenderDeal.deal._id}`}
                            />
                            <Tooltip id={`totalLTV_${lenderDeal.deal._id}`}>
                              <FlexRow utils={{ alignItems: 'center', mb: 3 }}>
                                <Heading as="h6" utils={{ mr: 'auto', fontSize: 'sm', color: 'white' }}>
                                  Total LTV
                                </Heading>
                                <Badge utils={{ bgColor: getBadgeVariant(Number(lenderDeal.matchScore?.totalLTV)) }}>{lenderDeal.matchScore?.totalLTV}%</Badge>
                              </FlexRow>
                              <Text utils={{ textAlign: 'left', fontSize: 'sm', color: 'gray400' }}>
                                {`This deal’s total LTV is ${totalLTVDiff}% ${totalLTV > deal.totalLTV ? 'greater' : 'less'} than the ${deal.name} deal.`}
                              </Text>
                            </Tooltip>
                          </Col>
                        )}
                      </Row>
                    </ListItem>
                  )}
                </List>
                <Divider utils={{ my: 7 }} />
                <FlexRow>
                  <UpdatedBy user={lenderDeal.deal.createdBy} updatedAt={lenderDeal.deal.updatedAt} createdAt={lenderDeal.deal.createdAt} />
                  {lenderDeal.deal.currentUserRole && (
                    <>
                      <Text utils={{ mx: 2 }}>{' · '}</Text>
                      <Link href={`/deal/${lenderDeal.deal._id}/home`} passHref target={isOverview ? undefined : '_blank'} style={{ position: 'relative' }}>
                        View deal
                        {!isOverview && <Icon icon={externalLink} size=".9em" utils={{ ml: 1 }} />}
                      </Link>
                    </>
                  )}
                </FlexRow>
              </TableCell>
            </TableCollapseBody>
          </TableRow>
        </>
      )}
    </TableCollapse>
  );
};

export default LenderDeal;
