import { getBaseApiUrl } from '~/helpers';
import { getAuthCookie } from '~/helpers/getAuthCookie';

export default async function uploadFile(file: File, onUploadError?: (error: string) => void): Promise<string> {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${getBaseApiUrl()}/api/uploadImage`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${getAuthCookie()}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const { imageId: fileId } = await response.json();
    return `${getBaseApiUrl()}/api/${file.type.startsWith('image/') ? 'images' : 'file'}/${fileId}`;
  } catch (error) {
    console.error('An error occurred:', error);
    onUploadError?.('Failed to upload file');
    throw error;
  }
}
