import { QuoteType } from '~/generated/graphql';

export const QuoteTypeLabels: Record<QuoteType, string> = {
  [QuoteType.SoftQuote]: 'Soft Quote',
  [QuoteType.LetterOfIntent]: 'Letter of Intent',
  [QuoteType.TermSheet]: 'Term Sheet',
};

export const QuoteTypeMobileLabels: Partial<Record<QuoteType, string>> = {
  [QuoteType.LetterOfIntent]: 'LOI',
};

export const quoteTypeOptions: ({ value: QuoteType; label: string; mobileLabel?: string })[] = Object.entries(QuoteTypeLabels)
  .map(([value, label]) => ({
    value: value as QuoteType,
    label,
    mobileLabel: QuoteTypeMobileLabels[value as QuoteType],
  }));

export default quoteTypeOptions;
